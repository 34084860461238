@import 'src/Assets/variables';

.question-form {
  width: 100%;
  min-height: 100px;
  border: 1px solid $ghost;
  background: rgba(229, 229, 229, 0.3);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;

  &-tooltip {
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
  }

  & .help-text {
    font-size: 14px;
  }

  &-upload .btn svg {
    font-size: 24px;
    position: relative;
    top: -2px;
  }

  &-input {
    margin-bottom: 15px;

    &-icon {
      width: 65px;
      height: 40px;
      border-radius: 100px;
      background: $main;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 9;
      padding: 7px 10px;

      & h5 {
        color: $white;
        display: inline-block;
        font-size: 12px;
        padding: 3px 0 0 5px;
        font-weight: bold;
      }

      &-round {
        width: 25px;
        height: 25px;
        border-radius: 100px;
        background: $white;
        text-align: center;
        display: inline-block;
      }
    }

    & span {
      position: absolute;
      bottom: 8px;
      right: 8px !important;
      left: auto !important;
      top: auto !important;
      color: $line;
    }

    & .form-control {
      height: 50px;
      padding: 0 75px;
      border-left: 0;
      border-top: 0;
      border-right: 0;
      outline: 0;
      box-shadow: none;
      border-bottom: 1px solid $line;
      background: transparent;

      &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $main;
      }

      &::placeholder {
        color: #BDBDBD;
      }
    }
  }

  &-answer {
    width: 100%;
    // height: 50px;
    border: 1px solid $line;
    border-radius: 10px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    &-fill {
      width: 100%;
    }

    &-selected {
      background: #cbf1ff;
      border: 1px solid $main;

      .question-form-answer-option {
        background: $main;
        color: $white;
        border: 1px solid $main;
      }

      .question-form-answer-text {
        color: $main-txt;
        font-weight: bold;
      }
    }

    &-items {
      width: 100%;
      display: flex;
    }

    &-option {
      width: 30px;
      height: 30px;
      border-radius: 60px;
      border: 1px solid $line;
      text-align: center;
      padding: 2px 0;
    }

    &-textarea {
      width: 100%;
      padding: 3px 8px;

      & span {
        position: absolute;
        bottom: 12px;
        right: 5px !important;
        left: auto !important;
        top: auto !important;
        color: $line;
      }

      & .form-control {
        min-height: 60px !important;
        position: relative;
        padding-left: 0;
        padding-right: 45px !important;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        background: transparent;
        top: -4px;
        box-shadow: none;
        outline: 0;
      }
    }

    &-text {
      width: 100%;
      padding: 3px 8px;

      & span {
        position: absolute;
        bottom: 12px;
        right: 5px !important;
        left: auto !important;
        top: auto !important;
        color: $line;
      }

      & .form-control {
        height: 35px !important;
        position: relative;
        padding-left: 0;
        padding-right: 45px !important;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        background: transparent;
        top: -4px;
        box-shadow: none;
        outline: 0;
      }
    }

    &-switch {
      padding: 5px 0;
      cursor: pointer;
    }

    &-cancel {
      width: 26px;
      height: 26px;
      border-radius: 100px;
      background: #828282;
      color: #FFF;
      text-align: center;
      position: relative;
      top: 3px;
      cursor: pointer;
      z-index: 9;

      & svg {
        font-size: 18px;
      }
    }
  }

  &-answer-key {
    & span {
      position: absolute;
      bottom: 8px;
      right: 8px !important;
      left: auto !important;
      top: auto !important;
      color: $line;
    }

    & .form-label{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }

    & .form-control {
      height: 90px;
      border-radius: 10px;
      outline: 0;
      box-shadow: none;
      border: 1px solid $line;
      background: transparent;

      &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $main;
      }

      &::placeholder {
        color: #BDBDBD;
      }
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 20px;

    &-remove {
      width: 30px;
      text-align: right;
      padding: 8px 0 5px 0;
      cursor: pointer;
    }
  }

  &-visual-brief {
    &-label-mark {
      display: inline-block;
      margin-left: 10px;
      background-color: $typography;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      color: $white;
      text-align: center;
      cursor: pointer;
      position: relative;

      &:hover > &-tooltip {
        visibility: visible;
      }

      &-tooltip {
        position: absolute;
        top: -30px;
        border-radius: 5px;
        text-transform: none;
        width: 280px;
        height: 25px;
        background-color: $typography;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(calc(-50% + 18px));
        visibility: hidden;
      }
    }
  }

  &-position {
    &-select {
      &:focus {
        border: 1px solid #000 !important;
      }

      &__option {
        &--is-selected {
          background: none !important;
          color: $main-txt !important;
          font-weight: bold !important;
        }
    
        &--is-focused{
          background: #F2767E !important;
          color: $white !important;
        }
      }

      &__multi-value {
        &__label {
          background: #FFDCE1 !important;
        }
        &__remove {
          background: #FFDCE1 !important;
        }
      }

      &__control {
        height: 50px;
        border-radius: 10px !important;
        border: 1px solid #ced4da;
        font-size: 14px;
        padding: 0 5px;

        &:focus {
          box-shadow: none;
          border: 1px solid #000;
        }
      }

      &__placeholder {
        color: $line !important;
      }
    }
  }

}

.custom-control-label {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $main;
  background-color: $main;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(223, 145, 184, 0.25); 
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $main; 
}
