@import 'src/Assets/variables';

.form-input {
  &-control {
    font-size: 14px;
    height: 50px;
    padding: 0 15px;
    border-radius: 10px;

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 2px solid $main;
    }

    &.is-invalid {
      border: 2px solid #dc3545;
      box-shadow: none;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
