@import 'src/Assets/variables';

.small-gold-text {
  font-size: 12px;
  font-weight: bold;
  color: $secondary;
  cursor: pointer;
  margin-bottom: 10px;
}

.bold-text {
  font-weight: bold;
}