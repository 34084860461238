@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Cartwheel';
  src: url('../src/Assets/Fonts/Cartwheel.otf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../src/Assets/Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../src/Assets/Fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../src/Assets/Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('../src/Assets/Fonts/Nunito-Regular.ttf') format('truetype');
}

body {
  font-family: 'Nunito-Regular', serif;
  font-weight: normal;
  font-style: normal;
  color: #636363;
}

.bold-text {
  font-weight: bold;
}

.container-fluid {
  width: 1280px;
}

.gold-text {
  color: #E3B26A;
}

.display-line-break {
  white-space: pre-line;
}