@import 'src/Assets/variables';

.contest-option-modal {
  &-content {
    border-bottom: 1px solid #636363;
    display: flex;
    padding: 16px;
    margin: 10px;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
      border-radius: 5px;
    }

    &-text {      
      &-title {
        color: $another;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        padding: 0px;
      }

      &-caption {
        color: #828282;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        padding: 0px;
      }
    }

    &-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #BDBDBD;
      margin: 10px 0px 0px auto;
      padding: 10px;

      & svg {
        margin-top: -8px;
        width: 20px;
      }
    }
  }

  & .modal-content {
    padding: 15px 0;
    border-radius: 20px;
  }

  & .modal-header {
    border: 0;

    & svg {
      font-size: 48px;
    }
  }

  & .modal-title {
    width: 100%;
    font-size: 16px;
    font-family: 'Poppins-SemiBold', serif;
    text-align: center;
  }

  & .modal-body {
    & .tab-content {
      padding: 30px 0 0 0;
    }

    & .nav-tabs {
      border: 0;

      & .nav-link {
        color: $line;
        border: 0;
        font-family: 'Poppins-SemiBold', serif;
        width: 50%;
        text-align: center;

        &.active {
          color: $main-txt !important;
          border-bottom: 3px solid $main-txt !important;
        }
      }
    }
  }
}
