.phone-input {
  font-size: 14px;
  height: 50px;
  // padding: 0 15px;
  border-radius: 10px;
  border: 1px solid #CED4DA;
  display: flex;
  align-items: center;

  &-help-text {
    font-size: 12px;
  }

  &-country-code {
    border-right: 1px solid #CED4DA;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 2%;
  }

  &-invalid {
    border: 1px solid #F2767E;
  }

  &-number {
    height: 100%;
    border: none;
    margin-left: 1%;
    width: 80%;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.country-code-invalid {
  border-right: 1px solid #F2767E;
}