@import 'src/Assets/variables';
.join-team-modal {
  &-box {
    padding: 20px 30px 20px 30px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;

    &-close {
      text-align: right;
      font-size: 24px;
      cursor: pointer;
    }

    & .modal-content {
      padding: 0;
      border-radius: 40px;
      transform: scale(0.85, 0.85);
      -ms-transform: scale(0.85, 0.85);
      -webkit-transform: scale(0.85, 0.85);
      -o-transform: scale(0.85, 0.85);
      -moz-transform: scale(0.85, 0.85);

      & .modal-body {
        & h4 {
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          color: #636363;
          width: 400px;
        }

        & p {
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          width: 400px;
        }

        & .join-team-illustration {
          width: 100%;
          text-align: center;
          padding-bottom: 10px;
          // position: absolute;
          // top: -180px;
          // left: 0;
        }

        & button {
          margin-top: 20px;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;

          & svg {
            font-size: 20px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }
}
