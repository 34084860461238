@import 'src/Assets/variables';

.add-friend-modal {
  &-done {
    text-align: center;

    &-image {
      margin-top: -40px;
      margin-bottom: 20px;
    }

    &-caption {
      margin-bottom: 50px;

      & h4 {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #636363;
      }

      & p {
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: #636363;
      }
    }

    &-invitation {
      &-link {
        color: #e3b26a;

        &:hover {
          color: #e3b26a;
          text-decoration: none;
        }

        &:visited {
          color: #e3b26a;
          text-decoration: none;
        }
      }
    }
  }

  &-submit {
    width: 100%;

    & svg {
      font-size: 20px;
      position: relative;
      top: -2px;
    }
  }

  &-list {
    width: 100%;
    padding: 0 10px;

    & span {
      display: inline-block;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 15px;
    }

    & .label-badge {
      margin-right: 10px;
    }
  }

  &-scrollable {
    width: 100%;
    max-height: 480px;
    overflow: auto;

    p {
      font-size: 12px;

      span {
        color: $main;
      }
    }
  }

  &-content {
    & span {
      display: inline-block;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &-member {
    width: 100%;
    min-height: 50px;
    padding-top: 20px;
    padding-left: 2.5%;
    margin-bottom: 0;
    cursor: pointer;

    &-profile {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;

      &-avatar {
        width: 75px;
        height: 75px;
        border-radius: 100px;

        & img {
          width: 75px !important;
          height: 75px !important;
        }
      }

      &-info {
        padding: 0 15px;
        font-size: 12px;

        & h2 {
          font-size: 14px;
          text-transform: uppercase;
          font-family: 'Poppins-SemiBold', serif;
          margin-bottom: 5px;
        }

        & p {
          font-size: 14px;
          color: $typography;
          font-weight: normal;
        }

        &-other {
          font-size: 18px;
          font-weight: bold;
          font-family: 'Poppins-SemiBold', serif;
          color: $secondary;

          & svg {
            color: $secondary;
            position: relative;
            top: -3px;
          }
        }
      }
    }

    &-not-found {
      text-align: center;
      color: $typography;
      font-size: 16px;
      line-height: 25px;
    }
  }

  &-search .form-control {
    font-size: 14px;
    color: $typography;
    padding: 15px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;

    &:focus {
      outline: 0;
      box-shadow: none;
      border: 2px solid $main;
    }

    &::placeholder {
      color: $line;
    }
  }

  & .modal-content {
    padding: 15px 0;
    border-radius: 20px;
  }

  & .modal-header {
    border: 0;

    & svg {
      font-size: 48px;
    }
  }

  & .modal-title {
    width: 100%;
    font-size: 16px;
    font-family: 'Poppins-SemiBold', serif;
    text-align: center;
  }

  & .modal-body {
    & .tab-content {
      padding: 30px 0 0 0;
    }

    & .nav-tabs {
      border: 0;

      & .nav-link {
        color: $line;
        border: 0;
        font-family: 'Poppins-SemiBold', serif;
        width: 50%;
        text-align: center;

        &.active {
          color: $main-txt !important;
          border-bottom: 3px solid $main-txt !important;
        }
      }
    }
  }

  & .badge-light {
    background-color: #f2767e !important;
    color: #ffffff !important;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
  }
}
