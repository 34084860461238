@import 'src/Assets/variables';

.free-material {
  &__modal{
    min-width: 700px;

    & .close {
      position: absolute;
      right: 5px;
      top: -50px;
      cursor: pointer;

      & svg {
        color: #FFF;
        font-size: 36px;
      }
    }

    & .modal-content {
      border-radius: 20px;
      transform: scale(0.9, 0.9);
      -webkit-transform: scale(0.9, 0.9);
    }

    & .modal-body {
      padding: 30px 50px;

      & .carousel {
        height: auto;
      }

      & .carousel .carousel-control-prev {
        left: -150px !important;
      }

      & .carousel .carousel-control-next{
        right: -150px !important;
      }

      & .carousel-control.left:hover, .carousel-control-prev:hover {
        background-image: none !important;
      }

      & .carousel-control.right:hover, .carousel-control-next:hover {
        background-image: none !important;
      }
    }
  }

  &__modal-materi-gratis{
    min-width: 1280px;
    max-width: 1366px;
    margin-top: 150px;

    & .close {
      position: absolute;
      right: 5px;
      top: -50px;
      cursor: pointer;

      & svg {
        color: #FFF;
        font-size: 36px;
      }
    }

    & .modal-content {
      border-radius: 20px;
      transform: scale(0.9, 0.9);
      -webkit-transform: scale(0.9, 0.9);
    }

    & .modal-body {
      padding: 30px 50px;

      & .carousel {
        height: auto;
      }

      & .carousel .carousel-control-prev {
        left: -75px !important;
      }

      & .carousel .carousel-control-next{
        right: -75px !important;
      }

      & .carousel-control.left:hover, .carousel-control-prev:hover {
        background-image: none !important;
      }

      & .carousel-control.right:hover, .carousel-control-next:hover {
        background-image: none !important;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    width: 100%;
    text-align: center;

    & h1 {
      font-size: 24px;
      font-family: 'Poppins-SemiBold', serif;
    }
  }

  &__small-title {
    padding: 10px 0 15px 0;
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins-SemiBold', serif;
  }

  &__description-col {
    padding: 0 25px;
    margin-top: 0;
  }

  &__tab-panel {
    background-color: #f2f2f2;
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 25px;
    margin-top: 20px;

    & .btn-download {
      background-color: #E3B26A;
      color: #fff;
      border-radius: 100px;
      padding: 10px 25px;
      position: absolute;
      top: 20px;
      right: 40px;
      font-weight: bold;
      line-height: 15px;

      img {
        height: 15px;
        margin-top: -5px;
        margin-right: 5px;
      }
    }

    & .carousel {
      margin: auto;
      width: 70%;
    }

    & .col {
      padding: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      cursor: pointer;
      // background-color: #e2e2e2;
      border-top: solid 4px #f2f2f2;

      .icon {
        height: 20px;
        margin-right: 10px;
        fill: #636363;
      }
    }

    & .col.active {
      border-top: solid 4px $main;
      background-color: #f2f2f2;
      color: $main;

      .icon {
        fill: $main;
      }
    }
  }

  &__action {
    width: 100%;
    padding: 25px 0;
    position: absolute;
    right: 0;
    bottom: -20px;

    & .custom-button .btn-secondary {
      background: #FFF !important;
      color: #555;
      margin-bottom: 15px;
    }

    & .custom-button .btn-primary {
      background: #A2DAF1!important;
    }
  }

  &__lesson-title {
    padding: 15px 0;

    & img {
      padding: 15px 0;
    }

    & h1 {
      font-family: 'Poppins-SemiBold', serif;
      font-size: 24px;
      line-height: 36px;
    }

    & .label-badge {
      display: inline-block;
      margin-right: 15px;

      & .badge-light {
        padding: 8px 12px;
        font-size: 14px;
      }
    }
  }

  &__description {
    & h5 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      margin-top: 7px;
    }

    & .wrapper {
      border-bottom: 1px solid #EBEBEB;
      margin-bottom: 15px;
      padding: 10px 0;
    }
  }
}