@import 'src/Assets/variables';

$sub-position: 30%;
$grade-position: 55%;
$view-position: 75%;


.admin-lesson-bank {
  &-table {
    &-header {
      &-text {
        &.grade {
          position: absolute;
          left: $grade-position;
          display: flex;
        }
      }
    }
  }

  &-filter {
    height: 45px;
    border: 1px solid #BDBDBD;
    padding: 0 25px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: $typography;
    cursor: pointer;

    &-container {
      position: relative;
      display: inline-block;
      &:not(:first-of-type) {
        margin-left: 2%;
      }

      & .form-control {
        border-radius: 25px;
        height: 45px;

        &:focus {
          border: 1px solid $main !important;
          box-shadow: none;
        }
      }
    }
  }

  &-table-row {
    &-text {

      &.subject {
        width: calc(#{$grade-position} - #{$sub-position});
      }

      &.grade {
        width: calc(#{$view-position} - #{$grade-position});
      }
    }
  }

  &-to-publish {
    &-arrow {
      margin-left: 5px;
    }

    &-teams-container {
      padding: 20px 0;
      border-bottom: 1px solid #E0E0E0;
      color: #636363;
      font-weight: bold;
      display: flex;
      align-items: center;
      position: relative;

      &-main {
        margin-left: 5%;
        width: calc(70% - 14px);
        position: relative;
        display: flex;
        align-items: center;
      }

      &-name {
        margin-left: 33px;
      }

      &-dot {
        width: 5px;
        height: 5px;
        background-color: $typography;
        border-radius: 100%;
        margin-left: 10px;
        display: inline-block;
        margin-right: 10px;
      }

      &-rank {
        font-family: 'Nunito-Regular', serif;
        font-weight: normal;
      }

      &-delete {
        position: absolute;
        right: 3%;
        cursor: pointer;
      }

    }

    &-check {

      &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 20px;
        width: 20px;  
      }

      &-checkmark {
        position: absolute;
        left: 0px;
        height: 20px;
        width: 20px;
        background-color: #FFFFFF;
        border: 1px solid #BDBDBD;
        border-radius: 4px;
        cursor: pointer;

        &:after {
          content: "";
          content: "";
          position: absolute;
          display: none;
          left: 7px;
          top: 1px;
          width: 6px;
          height: 16px;
          border: solid #FFFFFF;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
  
      }

      &-input:checked ~ &-checkmark {
        background-color: $button-main;
        border: $button-main;
      }
  
      &-input:checked ~ &-checkmark:after {
        display: block;
      }
  
    }
  }
}