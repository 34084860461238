@import '~spinthatshit/src/loaders';
@import 'src/Assets/variables';

.loader-wrap {
  height: 150px;
}

.loader {
  padding: 0;
  @include loader12($size: 10px, $gap: 20px, $align: middle, $color: $button-main);
}