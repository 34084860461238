@import 'src/Assets/variables';

.forgot-password {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) -11.11%, rgba(255, 255, 255, 0) 63.44%),#F2767E;

  &-left-section {
    display: flex;
    padding: 30px 0;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-right-section {
    display: flex;
    padding: 30px;
    justify-content: center;
  }

  &-round{
    display: flex;
    width: 575px;
    height: 575px;
    border-radius: 50%;
    background: rgba(237, 249, 253, 0.4);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  &-illustration {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    flex-grow: 0;
    bottom: 0;
    right: 0;
  }

  &-greetings h1{
    font-family: 'Poppins-Bold', serif;
    color: #000;
    font-size: 40px;
    line-height: 60px;
  }

  &-greetings p {
    font-family: 'Poppins-Bold', serif;
    color: #000;
    font-size: 16px;
    line-height: 50px;
  }

  &-form {
    width: 550px;
    min-height: 500px;
    background: #FFF;
    border-radius: 40px;
    padding: 60px 60px;

    &-error {
      font-size: 14px;
      margin-bottom: 15px;
      color: #EB5757
    }

    &-success {
      font-size: 14px;
      margin-bottom: 15px;
      color: #39DB80;
      white-space: pre-wrap;
      text-align: center;
    }

    &-title {
      font-family: 'Poppins-Bold', serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      color: $main;
      margin-bottom: 25px;
    }

    &-quick-login {
      display: flex;
      width: 100%;
      min-height: 60px;
      border: 1px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 40px;
      padding: 10px 15px 10px;
      margin-bottom: 30px;
      cursor: pointer;

      &-avatar {
        display: inline-block;
        width: 50px;
        height: 50px;
        background: #E3B26A;
        border-radius: 40px;
        font-family: 'Poppins-Bold', serif;
        font-size: 24px;
        text-align: center;
        color: #FFF;
        padding: 6px 0 0 0;
      }

      &-name {
        padding: 12px 15px;
      }

      &-icon {
        font-size: 28px;
        text-align: right;
        padding: 2px 15px;
        margin-left: auto;
      }
    }

    &-separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 30px;
      color: #ADADAD;
    }

    &-separator::before, &-separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #E5E5E5;
    }

    &-separator::before {
      margin-right: .5em;
    }

    &-separator::after {
      margin-left: .5em;
    }

    &-input-control {
      font-size: 14px;
      height: 50px;
      padding: 0 15px;
      border-radius: 10px;

      &::placeholder {
        color: #BDBDBD;
      }
    }

    &-input-label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-input-after {
      padding: 15px 0;
    }

    &-input-label-checkbox {
      font-size: 14px;
    }

    &-input-forgot-password {
      font-size: 14px;
      color: #E3B26A;
      font-weight: bold;

      &:hover{
        color: #E3B26A;
        text-decoration: none;
      }
    }

    &-input-button {
      width: 100%;
      height: 50px;
      border-radius: 30px;
      background: $main;
      outline: 0;
      border: 0;
      font-family: 'Poppins-Bold', serif;
    }

    &-input-button:hover {
      background: $main;
    }

    &-input-button:disabled {
      background: #F2767E;
    }

    &-input-button:active {
      background: #F2767E !important;
      box-shadow: none !important;
    }

    &-input-button:focus {
      background: #F2767E !important;
      box-shadow: none !important;
    }

    &-input-link {
      font-size: 14px;
      padding: 20px 0;
      color: #ADADAD;

      &-sign-up {
        color: #E3B26A;
        font-weight: bold;
      }

      &-sign-up:hover{
        color: #E3B26A;
        text-decoration: none;
      }
    }
  }
}

.filled {
  background-color: #FFDCE1 !important;
}
