@import 'src/Assets/variables';
$breakpoint: 720px;

.question-answer {
  & header {
    width: 100%;
    min-height: 200px;
    background: rgba(229, 229, 229, 0.2);
  }

  &-overview {
    width: 100%;
    min-height: 300px;
    padding: 30px 0 30px 0;
    border-bottom: 1px solid $line;

    &-title {
      font-family: 'Poppins-SemiBold', serif;
      padding: 15px 0;

      & h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }

    &-modal {
      max-width: 600px;

      & .modal-content {
        padding: 15px 0;
        border-radius: 20px;
      }

      & .modal-header {
        border: 0;

        & svg {
          font-size: 48px;
        }
      }

      & .modal-title {
        width: 100%;
        font-size: 16px;
        font-family: 'Poppins-SemiBold', serif;
        text-align: center;
      }

      & .modal-body {
      }

      &-profile:nth-child(1) {
        border-top: 1px solid $ghost;
      }

      &-profile {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        border-bottom: 1px solid $ghost;
        justify-content: space-between;

        &-whatsapp {
          & button {
            margin-top: 1px;
            margin-bottom: 1px;
          }

          & .custom-button .btn-primary {
            display: flex;
            padding: 10px 25px;

            & img {
              margin-right: 10px;
            }
          }
        }

        &-wrapper {
          width: 100%;
          display: flex;
        }

        &-avatar {
          width: 75px;
          height: 75px;
          border-radius: 100px;

          & img {
            width: 75px;
            height: 75px;
            border-radius: 100px;
          }
        }

        &-info {
          padding: 0 15px;

          & .label-badge {
            display: inline-block;

            & .badge-light {
              padding: 5px 10px;
            }
          }

          & h2 {
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'Poppins-SemiBold', serif;
            margin-bottom: 5px;
            display: inline-block;
          }

          & p {
            font-size: 14px;
            color: $typography;
            font-weight: normal;
          }

          &-other {
            margin-top: -10px;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Poppins-SemiBold', serif;
            color: $secondary;

            & svg {
              color: $secondary;
              position: relative;
              top: -3px;
            }
          }
        }
      }
    }

    &-member {
      width: 100%;
      display: flex;
      cursor: pointer;

      &-avatar {
        width: 75px;
        height: 75px;
        border-radius: 100px;
        border: 2px solid $white;

        &:not(:first-child) {
          margin-left: -8px;
        }

        & img {
          width: 75px;
          height: 75px;
          border-radius: 100px;
        }
      }
    }

    &-content {
      &-team {
        display: flex;
        align-items: center;
      }

      & h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        margin-top: 10px;
      }

      &-wrapper {
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 15px;
        padding: 10px 0;
        &:last-child {
          border: 0;
        }

        & a {
          text-decoration: none;
          color: $main;
          font-weight: bold;
        }
      }
    }

    &-view-more {
      color: #e3b26a;
      font-weight: bold;
    }
  }

  &-lesson {
    width: 100%;
    min-height: 300px;
    padding: 30px 0 0 0;

    &-title {
      font-family: 'Poppins-SemiBold', serif;
      padding: 15px 0;

      & h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }

    &-content {
      &-desc {
        padding: 20px 0;

        & a {
          text-decoration: none;
          color: $main;
          font-weight: bold;
        }

        & h2 {
          font-size: 20px;
          font-family: 'Poppins-SemiBold', serif;
          color: #e3b26a;
          display: inline-block;
        }

        & p {
          font-size: 16px;
          margin-top: 15px;
          line-height: 28px;
        }

        &-line {
          width: 71%;
          height: 1px;
          background-color: #bdbdbd;
          display: inline-block;
          float: right;
          margin-top: 20px;
        }
      }

      &-textarea {
        width: 100%;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        font-family: 'Nunito-Regular', serif;
        padding-top: 10px;
        padding-left: 10px;
        resize: none;

        &:active,
        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #bdbdbd;
        }

        &-container {
          position: relative;

          & span {
            color: #c4c4c4;
            font-family: 'Nunito-Regular';
            left: calc(100% - 80px) !important;
            top: calc(100% - 34px) !important;
          }
        }

        &-label {
          font-family: 'Poppins-Regular';
          font-weight: bold;
          display: block;
          color: $typography;
          font-size: 18px;
        }
      }

      & p {
        & .label-badge {
          display: inline-block;

          & .badge-light {
            padding: 12px 18px;
            font-size: 14px;
          }
        }
      }

      & h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        margin-top: 10px;
      }

      &-wrapper {
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 15px;
        padding: 10px 0;

        & h3 {
          font-size: 18px;
          font-family: 'Poppins-SemiBold', serif;
          margin-bottom: 5px;
        }

        & p {
          font-size: 14px;
        }

        &-button {
          display: flex;

          &-trash {
            padding: 15px;
            cursor: pointer;

            & svg {
              font-size: 18px;
            }
          }
          &-download {
            padding: 15px;
            cursor: pointer;

            & svg {
              font-size: 24px;
            }
          }
        }

        & .custom-button svg {
          font-size: 24px;
          position: relative;
          top: -2px;
        }
      }
    }

    &-view-more {
      color: #e3b26a;
      font-weight: bold;
    }
  }

  &-animation {
    width: 100%;
    min-height: 300px;
    padding: 30px 0 0 0;

    &-title {
      font-family: 'Poppins-SemiBold', serif;
      padding: 15px 0;

      & h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }

    &-content {
      &-desc {
        padding: 20px 0;

        & a {
          text-decoration: none;
          color: $main;
          font-weight: bold;
        }

        & h2 {
          font-size: 20px;
          font-family: 'Poppins-SemiBold', serif;
          color: #e3b26a;
          display: inline-block;
        }

        & p {
          font-size: 16px;
          margin-top: 15px;
          line-height: 28px;
        }

        &-line {
          width: 60%;
          height: 1px;
          background-color: #bdbdbd;
          display: inline-block;
          float: right;
          margin-top: 20px;
        }
      }

      & p {
        & .label-badge {
          display: inline-block;

          & .badge-light {
            padding: 12px 18px;
            font-size: 14px;
          }
        }
      }

      & h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        margin-top: 10px;
      }

      &-wrapper {
        border-bottom: 1px solid #ebebeb;
        margin-bottom: 15px;
        padding: 10px 0;

        & h3 {
          font-size: 18px;
          font-family: 'Poppins-SemiBold', serif;
          margin-bottom: 5px;
        }

        & p {
          font-size: 14px;
        }

        &-button {
          display: flex;

          &-trash {
            padding: 15px;
            cursor: pointer;

            & svg {
              font-size: 18px;
            }
          }
        }

        & .custom-button svg {
          font-size: 24px;
          position: relative;
          top: -2px;
        }
      }

      &-preview {
        border: 1px solid #bdbdbd;
        box-sizing: border-box;
        border-radius: 10px;
        height: 339px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
      }

      &-video-note {
        margin-top: 20px;

        span {
          color: $main;
        }
      }
    }

    &-view-more {
      color: #e3b26a;
      font-weight: bold;
    }
  }

  &-header {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    &-back {
      // position: absolute;
      top: 60px;
      left: 75px;
      cursor: pointer;

      & svg {
        font-size: 42px;
      }
    }

    &-title {
      width: 700px;
      height: auto;

      & h1 {
        font-size: 22px;
        font-family: 'Poppins-SemiBold', serif;
        line-height: 32px;
      }
    }

    &-submit {
      width: 300px;
      height: auto;
      display: flex;
      justify-content: flex-end;
    }
  }

  &-content {
    width: 100%;
    height: auto;
    padding: 50px 0;

    &-desc {
      padding: 20px 0;

      & a {
        text-decoration: none;
        color: $main;
        font-weight: bold;
      }

      & h2 {
        font-size: 20px;
        font-family: 'Poppins-SemiBold', serif;
        color: #e3b26a;
      }

      & p {
        font-size: 16px;
        margin-top: 15px;
        line-height: 28px;
      }
    }

    &-preview {
      // width: 380px;
      // height: 677px;
      border: 1px dashed $line;
      border-radius: 15px;
      background: url('../../Assets/Images/bg-question-preview.jpg') no-repeat center center;
      background-size: cover;
      padding: 15px;

      @media (min-height: $breakpoint) {
        height: 710px;
        width: 345px;
      }
      @media (max-height: $breakpoint) {
        height: 570px;
        width: 277px;
      }

      &-mask {
        // width: 380px;
        // min-height: 677px;
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        border-radius: 15px;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: space-between;
        justify-content: space-between;

        @media (min-height: $breakpoint) {
          height: 710px;
          width: 345px;
        }
        @media (max-height: $breakpoint) {
          min-height: 570px;
          width: 277px;
        }

        &-text {
          font-family: 'Cartwheel', serif;
          text-transform: uppercase;
          text-align: center;
          padding: 25px 0 0 0;

          & h1 {
            font-size: 64px;
            color: #fff;
          }
        }

        &-icon {
          text-align: center;

          & img {
            width: 100px;
          }
        }

        &-description {
          width: 100%;
          min-height: 280px;
          background: url('../../Assets/Images/box-correct-preview.png') no-repeat center center;
          background-size: contain;
          text-align: center;
          padding: 90px 60px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          & h1 {
            color: #fff;
            font-family: 'Cartwheel', serif;
            font-size: 28px;
            margin-bottom: 5px;
          }

          & h2 {
            color: #fff;
            font-family: 'Cartwheel', serif;
            font-size: 14px;
          }

          & span {
            font-size: 12px;
            color: #fff;
            font-family: 'Cartwheel', serif;
            text-decoration: underline;
            margin: 10px 0;
          }
        }

        &-key {
          width: 100%;
          height: 70px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          & p {
            font-size: 16px;
            color: #fff;
            text-transform: uppercase;
            font-family: 'Cartwheel', serif;
            line-height: 18px;
          }
        }

        &-button {
          width: 120px;
          height: 35px;
          background: #00ff13;
          background-size: contain;
          border-radius: 100px;
          padding: 0 3px;
          box-shadow: -2px 3px 0 #009b04;
          cursor: pointer;

          &-image {
            width: 100%;
            height: 40px;
            background: url('../../Assets/Images/button-correct.png') no-repeat center center;
            background-size: contain;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: #8ba631;
            font-family: 'Cartwheel', serif;
            margin-top: -1px;
          }
        }
      }

      &-wrapper {
        width: 100%;
        position: relative;
      }

      &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        &-ask img {
          width: 40px;
          height: 40px;
          opacity: 0.5;
          margin-top: 10px;
        }

        &-cancel img {
          width: 40px;
          height: 40px;
          opacity: 0.5;
          margin-top: 10px;
        }

        &-progress {
          width: 180px;
          height: 50px;
          background: url('../../Assets/Images/question-progress-preview.png') no-repeat;
          background-size: contain;
          text-align: center;
          padding: 5px 7px;

          & span {
            display: block;
            width: 100%;
            font-size: 12px;
            text-transform: uppercase;
            font-family: 'Cartwheel', serif;
            border-radius: 15px;
          }

          & span:nth-child(2) {
            margin-top: -6px;
          }
        }
      }

      &-content {
        width: 100%;
        height: 100px;
        display: flex;
        // margin-bottom: 330px;
        background: url('../../Assets/Images/wood-board.png') no-repeat;
        background-size: contain;
        padding: 15px 30px;
        align-items: center;
        word-break: break-all;

        @media (min-height: $breakpoint) {
          margin-bottom: 330px;
        }
        @media (max-height: $breakpoint) {
          margin-bottom: 230px;
        }

        & h1 {
          text-align: center;
          color: $white;
          // font-size: 16px;
          font-family: 'Cartwheel', serif;
          padding: 0;
          // line-height: 17px;

          @media (min-height: $breakpoint) {
            margin: -7px 0 0;
            font-size: 18px;
            line-height: 24px;
          }
          @media (max-height: $breakpoint) {
            margin: -20px 0 0;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      &-footer {
        width: 100%;
        height: auto;
        display: flex;
        padding: 15px;
        flex-direction: column;
        padding-top: 30px;

        &-answer {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          & img {
            width: 70px;
            height: 40px;
          }

          & input {
            border-radius: 0;
            height: 40px;
            font-family: 'Poppins-SemiBold', serif;
            font-style: italic;
            background: #e3d1bc;
            display: inline-block;
            width: 200px;

            &::placeholder {
              color: #777;
            }

            &:focus {
              background: #e3d1bc;
              outline: 0;
              box-shadow: none;
              border: 0;
            }
          }

          &-button {
            width: 110px;
            height: 45px;
            background: url('../../Assets/Images/answer-board.png') no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            word-break: break-all;

            & h1 {
              text-align: center;
              color: $white;
              font-size: 13px;
              font-family: 'Cartwheel', serif;
              margin: 0;
              padding: 0 3px;
            }
          }
        }
      }
    }
  }
}

.fixed {
  position: fixed;
  top: 5px;
}

.margin-top-50 {
  margin-top: 50px;
}

.no-border-bottom {
  border-bottom: 0;
}

.filled-button {
  & .custom-button .btn {
    text-align: left;
    background-color: #ffdce1 !important;
    color: $main !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
