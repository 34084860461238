@import 'src/Assets/variables';

.team-detail-card {
  width: 450px;
  min-height: 100px;
  border: 1px solid $ghost;
  padding: 20px;
  border-radius: 15px;
  background: $white;
  max-height: 595px;

  &-headline {
    width: 100%;
    border-bottom: 1px solid $ghost;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 10px;

    & h1 {
      font-size: 18px;
      color: $main;
      font-family: 'Poppins-SemiBold', serif;
      margin-top: 7px;
    }
  }

  &-scrollable {
    max-height: 528px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      visibility: collapse;
    }
  }

  &-member {
    width: 100%;
    min-height: 50px;
    margin-bottom: 15px;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 10px;

    &-profile {
      display: flex;
      flex-direction: row;

      &-avatar {
        width: 75px;
        height: 75px;
        border-radius: 100px;
        display: flex;
        justify-content: center;

        &-img {
          width: 75px !important;
          height: 75px !important;
          border-radius: 100%;
        }
      }

      &-info {
        padding: 0 15px;
        width: 80%;

        & h2 {
          font-size: 14px;
          text-transform: uppercase;
          font-family: 'Poppins-SemiBold', serif;
          margin-bottom: 5px;
        }

        & p {
          font-size: 14px;
          color: $typography;
          font-weight: normal;
        }

        &-other {
          margin-top: -10px;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Poppins-SemiBold', serif;
          color: $secondary;

          & svg {
            color: $secondary;
            position: relative;
            top: -3px;
          }
        }
      }
    }
  }

  &-request svg {
    font-size: 20px;
    position: relative;
    top: -2px;
  }
}

.add-bottom-border {
  border-bottom: 1px solid $ghost;
}

.middle-dot {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background: $typography;
  display: inline-block;
  position: relative;
  top: -3px;
}

.add-friend-modal-member-profile-button {
  // padding: 20px;
}


