@import 'src/Assets/variables';

.sign-up {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) -11.11%, rgba(255, 255, 255, 0) 63.44%), #F2767E;

  &-left-section {
    display: flex;
    padding: 30px 0;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-right-section {
    display: flex;
    padding: 30px;
    justify-content: center;
  }

  &-round {
    display: flex;
    width: 575px;
    height: 575px;
    border-radius: 50%;
    background: rgba(237, 249, 253, 0.4);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  &-illustration {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    flex-grow: 0;
    bottom: 0;
    right: 0;
  }

  &-greetings h1{
    font-family: 'Poppins-Bold', serif;
    color: #000;
    font-size: 40px;
    line-height: 60px;
  }

  &-greetings p {
    font-family: 'Poppins-Bold', serif;
    color: #000;
    font-size: 16px;
    line-height: 50px;
  }

  &-form {
    width: 550px;
    height: 750px;
    background: #FFF;
    border-radius: 40px;
    padding: 120px 60px;

    &-input {
      &-error {
        & ul {
          margin: 0 15px;
          padding: 0;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus {
        border: 1px solid $main;
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px #FFDCE1 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    &-error {
      font-size: 14px;
      margin-bottom: 25px;
      color: #EB5757
    }

    &-title {
      font-family: 'Poppins-Bold', serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 25px;
      color: #F2767E;
    }

    &-input-control {
      font-size: 14px;
      height: 50px;
      padding: 0 15px;
      border-radius: 10px;

      &::placeholder {
        color: #BDBDBD;
      }
    }

    &-input-label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-input-after {
      padding: 15px 0;
    }

    &-input-label-checkbox {
      font-size: 14px;
    }

    &-input-terms-policy {
      font-size: 14px;
      color: #E3B26A;
      font-weight: bold;

      &:hover{
        color: #E3B26A;
        text-decoration: none;
      }
    }

    &-input-button {
      width: 100%;
      height: 50px;
      border-radius: 30px;
      background: #F2767E;
      outline: 0;
      border: 0;
      font-family: 'Poppins-Bold', serif;
    }

    &-input-button:hover {
      background: #F2767E;
    }

    &-input-button:disabled {
      background: #F2767E;
    }

    &-input-button:active {
      background: #F2767E !important;
      box-shadow: none !important;
    }

    &-input-button:focus {
      background: #F2767E !important;
      box-shadow: none !important;
    }

    &-input-link {
      font-size: 14px;
      padding: 20px 0;
      color: #ADADAD;

      &-sign-in {
        color: #E3B26A;
        font-weight: bold;
      }

      &-sign-in:hover{
        color: #E3B26A;
        text-decoration: none;
      }
    }
  }
}

.set-min-height{
  min-height: 750px;
  padding: 60px 60px;
}
