@import 'src/Assets/variables';

.select-component {
  width: 230px;

  &__menu {
    margin-top: 15px !important;
    border-radius: 15px !important;
    padding: 15px 0;
  }

  &-library {
    border-radius: 100px;
  }

  &__single-value {
    color: $typography !important;
  }

  &__option {
    &--is-selected {
      background: none !important;
      color: $main-txt !important;
      font-weight: bold !important;
    }

    &--is-focused{
      background: #F2767E !important;
      color: $white !important;
    }
  }

  &__control {
    border-radius: 100px !important;
    border: 1px solid $ghost !important;
    color: $typography !important;
    box-shadow: none !important;
    padding: 0 15px;
    min-height: 40px !important;
    cursor: pointer !important;

    &--is-focused {
      border: 2px solid $main !important;
    }
  }
}
