@import 'src/Assets/variables';

.small-question {
  & .wrapper {
    & .mask {
      width: 90%;
      min-height: 505px;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0;
      right: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 15px;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;

      &__text {
        font-family: 'Cartwheel', serif;
        text-transform: uppercase;
        text-align: center;
        padding: 25px 0 0 0;

        & h1 {
          font-size: 64px;
          color: #FFF;
        }
      }

      &__icon {
        text-align: center;

        & img {
          width: 100px;
        }
      }

      &__description {
        width: 100%;
        height: 220px;
        background: url('../../Assets/Images/box-correct-preview.png') no-repeat center center;
        background-size: contain;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 60px;

        & h1 {
          color: #FFF;
          font-family: 'Cartwheel', serif;
          font-size: 26px;
        }

        & h2 {
          color: #FFF;
          font-family: 'Cartwheel', serif;
          font-size: 14px;
        }

        & span {
          font-size: 12px;
          color: #FFF;
          font-family: 'Cartwheel', serif;
          text-decoration: underline;
          margin: 10px 0;
        }
      }

      &__key {
        width: 80%;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;

        & p {
          font-size: 14px;
          color: #FFF;
          text-transform: uppercase;
          font-family: 'Cartwheel', serif;
          line-height: 18px;
        }
      }

      &__button {
        width: 120px;
        height: 35px;
        background-size: contain;
        border-radius: 100px;
        padding: 0 3px;
        cursor: pointer;

        & .image {
          width: 100%;
          height: 30px;
          background: url('../../Assets/Images/button-correct.png') no-repeat center center;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          color: #8ba631;
          font-family: 'Cartwheel', serif;
          margin-top: 7px;
        }
      }
    }
  }

  &__preview {
    width: 100%;
    margin: auto;
    height: 500px;
    border: 1px dashed $line;
    border-radius: 15px;
    background: url('../../Assets/Images/bg-question-preview.jpg') no-repeat center center;
    background-size: cover;
    padding: 10px 5px 10px 5px;

    & .wrapper {
      width: 100%;
      position: relative;
    }

    & .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      &__ask img {
        width: 40px;
        height: 40px;
        opacity: 0.5;
        margin-top: 10px;
      }

      &__cancel img {
        width: 40px;
        height: 40px;
        opacity: 0.5;
        margin-top: 10px;
      }

      &__progress {
        width: 180px;
        height: 50px;
        background: url('../../Assets/Images/question-progress-preview.png') no-repeat;
        background-size: contain;
        background-position-y: 10px;
        text-align: center;
        padding: 10px 5px 5px 5px;

        & span {
          display: block;
          width: 100%;
          font-size: 8px;
          text-transform: uppercase;
          font-family: 'Cartwheel', serif;
          border-radius: 15px;
          margin: 5px 0px 10px 0px;
        }

        & span:nth-child(2) {
          margin-top: -8px;
        }
      }
    }

    & .content {
      width: 100%;
      height: 60px;
      display: flex;
      margin-bottom: 200px;
      background: url('../../Assets/Images/wood-board.png') no-repeat;
      background-size: cover;
      padding: 5px 5px;
      align-items: center;
      justify-content: center;

      & h1 {
        text-align: center;
        color: $white;
        font-size: 10px;
        font-family: 'Cartwheel', serif;
        padding: 0;
        line-height: 10px;
      }
    }

    & .footer {
      width: 100%;
      height: auto;
      display: flex;
      // padding: 15px;
      flex-direction: column;

      &__answer {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        & .form-input {
          width: 80%;
          margin-right: 10px;
          margin-top: 30px;
        }

        & img {
          width: 20%;
          height: 30px;
          margin-top: 30px;
        }

        & input {
          border-radius: 0;
          height: 30px;
          font-family: 'Poppins-SemiBold', serif;
          font-style: italic;
          background: #e3d1bc;
          display: inline-block;
          // width: 160px;

          &::placeholder {
            color: #777;
            font-size: 8px;
          }

          &:focus {
            background: #e3d1bc;
            outline: 0;
            box-shadow: none;
            border: 0;
          }
        }

        &-button {
          width: 100px;
          height: 45px;
          background: url('../../Assets/Images/answer-board.png') no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;

          & h1 {
            text-align: center;
            color: $white;
            font-size: 10px;
            font-family: 'Cartwheel', serif;
            margin: 0;
            padding: 0px;
          }
        }
      }
    }
  }

  &__question-indicator {
    text-align: center;
    padding: 10px 0 15px 0;
    font-size: 16px;
    font-family: 'Poppins-SemiBold', serif;
  }
}

.question-answered--style {
  color: #FF9908;
  margin-bottom: 8px;
}

.question-answered--count {
  color: #000;
  background: #BDBDBD;
}

.margin-top--80 {
  margin-top: 80px;
}

.margin-top--50 {
  margin-top: 50px;
}

