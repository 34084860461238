@import 'src/Assets/variables';

.publish-modal {

  &-header {
    border-bottom: 1px solid #828282;
    padding-bottom: 20px;
    padding-top: 20px;

    &-heading {
      font-family: 'Poppins-Bold';
      color: $typography;
      display: block;
      text-align: center;
      font-size: 24px;
    }

    &-sub {
      font-family: 'Poppins-SemiBold';
      display: block;
      color: $main-txt;
      text-align: center;
      margin-top: 20px;
    }

    &-deadline {
      display: block;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      font-size: 15px;
      margin-top: 10px;
    }
  }

  &-content {
    margin-top: 20px;

    &-left-text {
      font-weight: bold;
      color: $typography;
    }
  }

  &-btn {

    &-container {
      width: 100%;
      padding: 50px 0;
      position: absolute;
      right: 0;
      top: calc(100% - 20px);
      display: flex;
      justify-content: space-between;
    }

    &-left {
      display: flex;
      width: 140px;
      justify-content: space-between;
    }

    &-circle {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    font-size: 26px;
    color: #828282;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    
  }

    &-main {
      width: 36%;
    }
  }
}