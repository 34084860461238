@import 'src/Assets/variables';

.my-projects {

  background-image: url('../../Assets/Images/my-projects-background.png');
  background-position: top;
  background-repeat: no-repeat;

  &-heading {
    margin-top: 150px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-title {
      font-family: 'Poppins-Bold';
      font-weight: 800;
      color: $main;
    }
  }

  &-tabs {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 1150px;
    font-family: 'Poppins-Regular';
    font-weight: bold;
    color: #E0E0E0;
    font-size: 20px;

    &-tab {
      width: 25%;
      cursor: pointer;
      padding-bottom: 20px;
      text-align: center;
      border-bottom: 4px solid #E0E0E0;


      &.active {
        border-bottom: 4px solid $button-main;
        color: $button-main;
      }
    }
  }

  &-container {
    width: 80%;
    padding-bottom: 60px;
    padding-left: 0px;
    padding-right: 0px;

    &-row-col {
      margin-bottom: 30px;
    }

  }

  &-filter {
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    & .btn-secondary {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED !important;
      margin-right: 15px;

      &.active {
        color: $white !important;
        background: $main !important;
      }

      &.focus {
        box-shadow: none;
      }
    }

    &-right {
      display: flex;
      width: 37%;
      justify-content: space-between;

      &-btn {
        @extend .btn-secondary;
        cursor: pointer;
        margin-right: 0px !important;

        & > input[type=checkbox] {
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        }
      }

      &-icon-filter {
        margin-left: 10px;
      }
    }
  }

  &-no-projects {
    text-align: center;

    &-img {
      margin-left: calc(50% - 55px);
      transform: translateX(-50%);
    }

    &-heading {
      font-family: 'Poppins-Regular';
      font-weight: bold;
      display: block;
      color: $typography;
      margin-top: 20px;

      &-sub {
        font-family: 'Nunito-Regular';
        margin-top: 10px;
        color: $typography;
      }
    }

    &-btn {
      width: 25%;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 20px;
    }

  }
}