@import 'src/Assets/variables';

.papan-pintar {
  width: 100%;
  min-height: 100vh;
  background: #FFF;

  &-jumbotron {
    display: flex;
    width: 100%;
    min-height: 450px;
    background: url('../../Assets/Images/background-papan-pintar.png');
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 150px;

    & .video-container {
      padding-top: 20px;

      & .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
      }
      
      & .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }


    & h1 {
      font-family: 'Poppins-Bold', serif;
      font-size: 56px;
      padding: 20px 0;
      // color: $main;
      color: $main;
    }

    & p {
      font-size: 18px;
      margin-bottom: 75px;
      line-height: 36px;

      & a {
        color: #E3B26A;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  &-toggle {
    width: 100%;
    min-height: 100px;
    padding: 30px 0;
    z-index: 2;
    background: #FFF;

    &-filter {
      padding-top: 10px;
      display: flex;

      & .active-btn-filter {
        border: 1px solid $main !important;
        color: $main !important;
        font-weight: bold;
      }
    }

    &-filter button {
      width: 175px;

      &:hover {
        color: $main !important;
        font-weight: bold;
      }
    }

    &-dropdown {
      display: inline-block;
      border-right: 1px solid #DDD;
      margin-right: 15px;
      width: 200px;
      position: relative;
      top: 12px;
      padding-right: 15px;

      .multi-select {
        --rmsc-main: $main;
        --rmsc-hover: #f1f3f5;
        --rmsc-selected: #e2e6ea;
        --rmsc-border: $main;
        --rmsc-gray: #aaa;
        --rmsc-bg: #fff;
        --rmsc-p: 10px; /* Spacing */
        --rmsc-radius: 4px; /* Radius */
        --rmsc-h: 38px; /* Height */

        & .checkbox-container {
          margin-bottom: 0px;
        }
      }

      & .multi-select .dropdown-heading {
        border-radius: 100px !important;
        border: 1px solid #EDEDED !important;
        height: 40px!important;

        &:hover {
          border: 1px solid $main!important;
          transition: 0.3s ease;
        }

        & .dropdown-heading-value {
          padding: 3px 15px 0 15px!important;
          color: #636363!important;

          & span {
            color: #636363!important;
          }
        }
      }
    }

    & .btn {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED;
      margin-right: 15px;
      box-shadow: none;
    }

    & .btn-primary {
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        color: #636363!important;
        border: 1px solid $main!important;
      }
    }

    & .btn-secondary {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED!important;
      margin-right: 15px;
      box-shadow: none;

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        color: #FFF!important;
        background: $main!important;
      }
    }

    & .btn-primary:hover {
      border: 1px solid $main !important;
    }

    & .btn-secondary:hover {
      color: #FFF !important;
      background: $main !important;
    }
  }
}

.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  margin-top: 150px;
}
