@import 'src/Assets/variables';

.profile-header {
  margin-top: 150px;
  padding-top: 20px;
  display: flex;

  &-img-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    &-img {
      width: 200px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 100%;
    }
  }

  &-image {
    margin: 15px;
    width: 200px;
  }

  &-details {
    margin: 15px;

    &-name {
      display: flex;

      & button {
        margin: 10px;
      }
      & h1 {
        size: 48px;
        font-weight: bold;
        line-height: 65px;
        color: #636363;
        font-family: 'Poppins-Bold', serif;
      }
    }

    & h5 {
      font-weight: bold;
      font-family: 'Poppins-Bold', serif;
      size: 20px;
      color: #636363;
      line-height: 30px;
    }
  }

  &-social-media {
    padding: 10px 0px;

    & button {
      margin-right: 5px;
      padding: 10px 35px;
    }
  }
}

.profile-tabs {
  margin-top: 15px;
  padding: 15px 0px;
  display: flex;

  &-tab {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    margin: 0px 10px;
    padding: 10px 60px;
    cursor: pointer;

    &-image {
      margin: 10px;
    }

    &-text {
      padding: 10px;
      color: #636363;

      & h1 {
        font-family: 'Poppins-Bold', serif;
        font-weight: bold;
        size: 48px;
      }

      & p {
        font-family: 'Nunito-Regular', serif;
        font-weight: bold;
        size: 16px;
        line-height: 22px;
      }
    }

    & #text-active {
      padding: 10px;
      color: $another;
    }
  }

  & #active {
    border-bottom: 6px solid $another;
    pointer-events: none;
  }
}

.profile-reviews {
  margin: 30px;
  border-top: 1px solid #e0e0e0;

  &-received {
    border-top: 1px solid #e0e0e0;
    padding: 28px 0px;
    display: flex;

    &-avatar {
      margin-right: 25px;
    }

    &-details {
      width: 40%;
      margin-right: 25px;

      & h3 {
        font-family: 'Poppins-Bold', serif;
        size: 20px;
        line-height: 30px;
        color: #636363;
      }

      & p {
        font-family: 'Nunito-Regular', serif;
        font-weight: normal;
        size: 16px;
        line-height: 25px;
        color: #636363;
      }
    }

    &-contents {
      &-rating {
        display: flex;
        margin: 5px;

        &-non-active {
          font-size: 30px;
          color: #c4c4c4;
          border-radius: 2px;
          margin: 0px 5px;
        }

        &-active {
          font-size: 30px;
          color: #e3b26a;
          border-radius: 2px;
          margin: 0px 5px;
        }

        & p {
          margin-left: auto;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: #636363;
        }
      }

      &-button {
        margin: 5px;

        & button {
          background: rgba(229, 229, 229, 0.6);
          border-radius: 5px;
          border: none;
          cursor: default;
          margin: 5px;
        }
      }

      &-testimonial {
        padding: 5px 10px;
      }
    }
  }

  &-button {
    padding: 30px 0px;

    & button {
      margin-right: 10px;
    }
  }
}

.profile-contests {
  margin: 0px 30px 30px 30px;

  &-earnings {
    display: flex;
    background: rgba(227, 178, 106, 0.2);
    border-radius: 10px;
    padding: 12px 36px;
    margin: 10px 0px;

    & p {
      font-family: 'Nunito-Regular', serif;
      font-weight: bold;
      size: 16px;
      color: #e3b26a;
      margin-top: 15px;
    }

    & h1 {
      font-family: 'Poppins-Bold', serif;
      margin-left: auto;
      margin-top: 5px;
      font-weight: bold;
      font-size: 32px;
      line-height: 43px;
      color: #e3b26a;
    }
  }

  &-cards {
    margin: 10px 0px;
  }
}
