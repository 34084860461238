@import 'src/Assets/variables';

.sign-in-modal-box {
  padding-top: 100px;
  margin-bottom: 0;

  &-close {
    text-align: right;
    font-size: 24px;
    padding: 0 30px;
    cursor: pointer;
  }

  & .modal-content {
    padding: 0;
    border-radius: 40px;
    transform: scale(0.85, 0.85);
    -ms-transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
    -moz-transform: scale(0.85, 0.85);

    & .modal-body {
      & .sign-in-form-quick-login {
        border: 0;
        background: $main;
        color: #FFF;
      }

      & .sign-in-form {
        width: auto;
        height: auto;
        padding: 30px;
      }

      & .sign-in-form-title {
        margin-bottom: 15px;
        text-align: center;
        font-size: 28px;
        color: #777;
        font-family: 'Poppins-SemiBold', serif;
      }

      & .sign-in-form-title.active {
        color: $main;
      }

      & .sign-in-form-desc {
        margin-bottom: 25px;
        text-align: center;
        font-size: 14px;
      }

      & .sign-in-form-separator {
        margin-bottom: 15px;
      }

      & .form-group {
        padding: 0.5rem;
        margin-bottom: 0;
      }

      & .sign-in-form-input-link {
        padding: 10px 0;
      }

      & .sign-in-form-illustration {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -180px;
        left: 0;
      }

      & .sign-in-form-quick-login-avatar {
        background: $white;
        color: $main;
      }
    }
  }
}
