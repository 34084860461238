$white: #FFFFFF;

// $main: #A2DAF1;
// $main-txt: #64B3D4;
$secondary: #E3B26A;
$typography: #636363;
$line: #BDBDBD;
$ghost: #E0E0E0;
// $button-main: #83CDEC;

//$main: #F2767E;
//$main-txt: #d47c84;
$main: #f2767e;
$main-txt: #f2767e;
$button-main: #f2767e;

//$another: #64B3D5;
$another: #d94b69;
$another-lighter: #e59da8;

$admin-sidebar-width: 20%;
$admin-content-width: 80%;
