@import 'src/Assets/variables';

$title-position: 5%;
$sub-position: 35%;
$grade-position: 55%;
$proposal-position: 70%;
$status-position: 80%;
$action-position: 87%;

.admin-lesson-requests {
  &-content {
    width: $admin-content-width;
    margin-left: $admin-sidebar-width;
    padding-bottom: 60px;
  }

  &-heading {
    padding-top: 80px;
    padding-left: 5%;
    margin-bottom: 30px;

    &-text {
      display: inline-block;
      font-family: 'Poppins-Bold';
      color: $typography;
    }

    &-img {
      height: 36px;
      filter: sepia(100%) hue-rotate(190deg) saturate(500%);
      margin-top: -10px;
      margin-right: 20px;
    }

    &-btn {
      float: right;
      margin-right: 5%;
      width: 20%;
    }
  }

  &-tabs {
    width: 90%;
    margin-left: 5%;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    font-family: 'Poppins-Bold';
    color: #E0E0E0;

    &-tab {
      padding-bottom: 10px;
      width: 20%;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $main-txt;
        border-bottom: 3px solid $main-txt;
      }
    }
  }

  &-filters {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    border-bottom: 1px solid #E0E0E0;

    .multi-select {
      --rmsc-main: $main;
      --rmsc-hover: #f1f3f5;
      --rmsc-selected: #e2e6ea;
      --rmsc-border: $main;
      --rmsc-gray: #aaa;
      --rmsc-bg: #fff;
      --rmsc-p: 10px; /* Spacing */
      --rmsc-radius: 4px; /* Radius */
      --rmsc-h: 38px; /* Height */

      & .checkbox-container {
        margin-bottom: 0px;
      }
    }

    & .multi-select .dropdown-heading {
      border-radius: 100px !important;
      border: 1px solid #BDBDBD !important;
      height: 45px!important;

      &:hover {
        border: 1px solid $main!important;
        transition: 0.3s ease;
      }

      & .dropdown-heading-value {
        padding: 3px 15px 0 15px!important;
        color: #636363!important;

        & span {
          font-size: 13px;
          color: #636363!important;
        }
      }
    }
    
  }

  &-filter {
    height: 45px;
    border: 1px solid #BDBDBD;
    padding: 0 25px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: $typography;
    cursor: pointer;

    &.active {
      border: 1px solid $button-main;

      & .admin-lesson-requests-filter-icon {
        transform: rotate(180deg);
      }
    }

    &.period {
      width: fill-available;
      width: -moz-available;
      width: -webkit-fill-available;
    }


    &-icon {
      color: #C4C4C4;
      font-weight: 100;
      margin-left: 20px;
    }

    &-container {
      position: relative;
      display: inline-block;
      &:not(:first-of-type) {
        margin-left: 2%;
      }
    }

    &-expand {
      position: absolute;
      top: calc(100% + 10px);
      z-index: 10;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 15px;
      left: 0;
      padding-top: 20px;

      &.classes {
        width: 150%;
      }

      &.subjects {
        width: 200%;
      }

      &.status {
        width: 110%;
      }

      &.period {
        left: auto;
        right: 0;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
        padding-bottom: 20px;
        width: 120%;
      }

      &-deadline {
        border-top: 1px solid #E0E0E0;
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;

        &-hapus {
          color: #E0E0E0;
          cursor: pointer;
        }

        &-simpan {
          color: $secondary;
          cursor: pointer;
        }
      }

      &-status-option {
        cursor: pointer;
        padding: 10px 0 10px 20px;
        color: $typography;
        
        &:hover {
          background-color: #FAFAFA;
        }
      }
    }
  }

  &-period-filter {
    display: flex;
    align-items: center;
    color: #000000;
    position: relative;
    min-width: 321.92px;
    float: right;


    &-text {
      margin-right: 20px;
    }

    &-date-text {
      color: $typography;
      font-size: 12px;
      font-weight: bold;
      display: block;
      margin-left: 5%;
    }

    &-inputs {
      width: 90%;
      margin-left: 5%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 20px;

      & span {
        font-size: 12px;
        font-weight: bold;
        color: $typography;
      }
    }

    &-input {
      border: 1px solid $line;
      width: 42%;
      border-radius: 10px;
      height: 47px;
      padding-left: 10px;
      color: $typography;

      &:active,
      &:focus {
        border: 2px solid $another;
        outline: none;
      }
    }
  }

  &-table {
    width: 90%;
    margin-left: 5%;
    position: relative;

    &-header {
      color: $typography;
      font-size: 14px;
      font-weight: bold;
      padding-top: 20px;
      padding-bottom: 34px;
      border-bottom: 1px solid $typography;

      &-text {
        position: absolute;
        &.subject {
          left: $sub-position;
        }

        &.grade {
          left: $grade-position;
          display: flex;
        }
        &.proposal {
          left: $proposal-position;
          display: flex;
        }
        &.status {
          left: $status-position;
        }
        &.action {
          left: $action-position;
        }

        &-arrow {
          display: flex;
          flex-flow: column nowrap;
          margin-left: 10px;
          justify-content: center;
          height: 18px;

          & > i {
            cursor: pointer;
          }
        }
      }
    }

    &-row {
      padding: 20px 0;
      border-bottom: 1px solid #E0E0E0;
      color: $typography;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #FAFAFA;

        & .admin-lesson-requests-table-row-text.title {
          color: $main-txt;
          text-decoration: underline;
        }
      }

      &-text {
        display: inline-block;
        
        &.title {
          margin-right: 5%;
          width: calc(#{$sub-position} - #{$title-position});
        }

        &.subject {
          width: calc(#{$grade-position} - #{$sub-position});
        }

        &.grade {
          width: calc(#{$proposal-position} - #{$grade-position});
          padding-left: 10px;
        }
        &.proposal {
          width: calc(#{$status-position} - #{$proposal-position});
          padding-left: 10px;
        }
        &.action {
          padding-left: 10px;
        }

      }

      &-btn {
        position: absolute;
        right: 5%;

        &.padding {
          right: calc(5% + 35px);
        }
      }  
    }

  }

  &-pagination {
    margin-top: 20px;
    margin-left: 5%;
    width: auto;
    font-size: 14px;
    color: $typography;
    display: flex;
    align-items: center;

    &-arrow {
      font-size: 10px;
      margin-right: 20px;
      cursor: pointer;
    }

    &-num {
      margin-right: 20px;
      width: 20px;
      border-radius: 100%;
      text-align: center;
      cursor: pointer;

      &.active {
        background-color: $secondary;
        color: $white;
      }
    }
  }

  &-check {
    position: relative;
    cursor: pointer;
    padding: 10px 0 10px 40px;
    color: $typography;

    &:hover {
      background-color: #F2F2F2;
    }

    &.active {
      font-weight: bold;
    }

    &-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &-checkmark {
      position: absolute;
      top: 12px;
      left: 10px;
      height: 20px;
      width: 20px;
      background-color: $white;
      border: 1px solid #BDBDBD;
      border-radius: 4px;

      &:after {
        content: "";
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 14px;
        border: solid #FFFFFF;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    &-input:checked ~ &-checkmark {
      background-color: $button-main;
      border: $button-main;
    }

    &-input:checked ~ &-checkmark:after {
      display: block;
    }

  }

  // Calendar Styles

  &-calendar {
    border: none !important;
    margin-left: 50%;
    transform: translateX(-50%);

    // Month and arrow button styles 
    & .react-calender__navigation {
      background-color: rgba(224, 224, 224, .5);
    }

    // Month text Styles
    & .react-calendar__navigation__label__labelText {
      color: $typography;
      font-family: 'Poppins-Bold';
      font-weight: bold;
      font-size: 19px;
    }

    // Arrow on sides of month
    & .react-calendar__navigation__arrow {
      font-size: 36px;
      color: #828282;
    }

    // Weekday styles 
    & .react-calendar__month-view__weekdays {
      font-family: Nunito Sans;
      font-weight: bold;
      font-size: 12px;
      color: $main-txt;
      border-bottom: 1px solid #E0E0E0;
      width: 90%;
      margin-left: 5%;

      // Remove underline from individual weekday
      & abbr[title] {
        text-decoration: none;
      }
    }

    // Dates Container
    & .react-calendar__month-view__days {
      width: 90%;
      margin-left: 5%;
      margin-top: 20px;
    }

    // Single Date 
    & .react-calendar__month-view__days__day {
      color: $typography;
      font-family: 'Nunito-Regular';
      font-size: 14px;
      font-weight: bold;
      width: 40px;
      height: 40px;
      border-radius: 100%;

      //Dates of another month
      &--neighboringMonth {
        color: $line;
      }

      // Today's Date
      &.react-calendar__tile--now {
        background-color: rgba(224, 224, 224, .5);

        &:hover {
          background-color: $button-main;
          color: $white;
        }
      }

      // Selected Date
      &.react-calendar__tile--active {
        background-color: $button-main !important;
        color: $white;
      }
    }
  }
}