.blue-border{
  width: 100%;
  //height: 275px;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  display: flex;
  flex-wrap:  wrap;
  justify-content: flex-start;
  align-items:  flex-start;
  .label-badge{
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    height: fit-content;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  .clear-all{
    position: absolute; top: 0.2rem; bottom: 0; right: 1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    color:rgba(227, 178, 106, 1);
    &:hover{
      cursor: pointer;
    }
  }

}
.invite-disclosure{
  font-size: 0.8rem;
  padding: 1rem;
}
.confirmation-modal-badge{
  //align-items: center;
  //justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.confirmation-switch {
  //justify-content: space-between ;
}
