@import 'src/Assets/variables';
$breakpoint: 720px;

.proposal-summary {
  &__modal {
    max-width: 700px;
    height: 500px;

    & .close {
      position: absolute;
      right: 5px;
      top: -50px;
      cursor: pointer;

      & svg {
        color: #FFF;
        font-size: 36px;
      }
    }

    & .modal-content {
      border-radius: 20px;
      transform: scale(0.9, 0.9);
      -webkit-transform: scale(0.9, 0.9);
    }

    & .modal-body {
      padding: 30px 50px;

      & .carousel {
        height: 570px;
      }

      & .carousel .carousel-control-prev {
        left: -150px !important;
      }

      & .carousel .carousel-control-next{
        right: -150px !important;
      }

      & .carousel-control.left:hover, .carousel-control-prev:hover {
        background-image: none !important;
      }

      & .carousel-control.right:hover, .carousel-control-next:hover {
        background-image: none !important;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    width: 100%;
    text-align: center;

    & h1 {
      font-size: 24px;
      font-family: 'Poppins-SemiBold', serif;
    }
  }

  &__content {
    & .task {
      width: 500px;
      height: 500px;
      background: linear-gradient(180deg, $another 27%, #fafafa 27%);
      margin: 30px auto 0 auto;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      padding: 30px 50px;
    }

    & .proposal__progress {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & h1 {
        font-size: 18px;
        font-family: 'Poppins-SemiBold', serif;
        color: #FFF;
      }

      & h1:nth-child(2) {
        font-size: 28px;
        position: relative;
        top: -5px;
      }
    }

    & .progress__bar {
      width: 100%;

      & .progress {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 100px;
      }

      & .progress-bar {
        background: #FFF;
      }
    }

    & .task__wrapper {
      width: 100%;
      height: 350px;
      background: #FFF;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      border-radius: 10px;
      padding: 15px;
    }

    & .task__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $ghost;
      padding: 20px 15px 0;
    }

    & .task-description {
      & h1 {
        font-size: 14px;
        font-family: 'Poppins-SemiBold', serif;
      }

      & p {
        font-size: 12px;
      }
    }

    & .task-label {
      padding: 5px 0;
    }

    & .file__name {
      font-family: 'Poppins-SemiBold', serif;
      margin: 10px 0 20px 0;
      text-align: center;
    }

    & .file-vot__preview {
      width: 100%;
      height: 510px;
      border: 1px solid $line;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      overflow: auto;
      padding: 10px;
    }
  }

  &__indicator {
    display: flex;
    justify-content: center;
    padding: 15px 0 0;
    font-family: 'Poppins-Regular', serif;
    font-size: 14px;
    color: #555;
  }

  &__action {
    width: 100%;
    padding: 50px 15px;
    position: absolute;
    right: 0;
    bottom: -120px;

    // & .custom-button .btn-secondary {
    //   background: transparent !important;
    //   color: #FFF;
    // }
  }

  &__preview-wrapper {
    & .mask {
      width: 285px;
      min-height: 586px;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0;
      border-radius: 15px;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;

      &__text {
        font-family: 'Cartwheel', serif;
        text-transform: uppercase;
        text-align: center;
        padding: 25px 0 0 0;

        & h1 {
          font-size: 64px;
          color: #FFF;
        }
      }

      &__icon {
        text-align: center;

        & img {
          width: 100px;
        }
      }

      &__description {
        width: 100%;
        height: 220px;
        background: url('../../Assets/Images/box-correct-preview.png') no-repeat center center;
        background-size: contain;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 80px;

        & h1 {
          color: #FFF;
          font-family: 'Cartwheel', serif;
          font-size: 28px;
        }

        & h2 {
          color: #FFF;
          font-family: 'Cartwheel', serif;
          font-size: 14px;
        }

        & span {
          font-size: 12px;
          color: #FFF;
          font-family: 'Cartwheel', serif;
          text-decoration: underline;
          margin: 10px 0;
        }
      }

      &__key {
        width: 80%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        & p {
          // font-size: 14px;
          @media (min-height: $breakpoint) {
            font-size: 14px;
          }
          @media (max-height: $breakpoint) {
            font-size: 12px;
          }
          color: #FFF;
          text-transform: uppercase;
          font-family: 'Cartwheel', serif;
          line-height: 18px;
        }
      }

      &__button {
        width: 120px;
        height: 35px;
        background: #00ff13;
        background-size: contain;
        border-radius: 100px;
        padding: 0 3px;
        box-shadow: -2px 3px 0 #009b04;
        cursor: pointer;

        & .image {
          width: 100%;
          height: 40px;
          background: url('../../Assets/Images/button-correct.png') no-repeat center center;
          background-size: contain;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          color: #8ba631;
          font-family: 'Cartwheel', serif;
          margin-top: -1px;
        }
      }
    }
  }

  &__preview {
    width: 285px;
    height: 586px;
    border: 1px dashed $line;
    border-radius: 15px;
    background: url('../../Assets/Images/bg-question-preview.jpg') no-repeat center center;
    background-size: cover;
    padding: 15px;

    & .wrapper {
      width: 100%;
      position: relative;
    }

    & .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 11px;

      &__ask img {
        width: 30px;
        height: 30px;
        opacity: 0.5;
        margin-top: 10px;
      }

      &__cancel img {
        width: 30px;
        height: 30px;
        opacity: 0.5;
        margin-top: 10px;
      }

      &__progress {
        width: 160px;
        height: 40px;
        background: url('../../Assets/Images/question-progress-preview.png') no-repeat;
        background-size: contain;
        text-align: center;
        padding: 5px 7px;

        & span {
          display: block;
          width: 100%;
          font-size: 11px;
          text-transform: uppercase;
          font-family: 'Cartwheel', serif;
          border-radius: 15px;
        }

        & span:nth-child(2) {
          margin-top: -8px;
        }
      }
    }

    & .content {
      width: 100%;
      height: 90px;
      display: flex;
      margin-bottom: 150px;
      background: url('../../Assets/Images/wood-board.png') no-repeat;
      background-size: contain;
      padding: 15px 30px;
      align-items: center;

      & h1 {
        text-align: center;
        color: $white;
        font-size: 14px;
        font-family: 'Cartwheel', serif;
        padding: 0;
        margin: -7px 0 0;
        line-height: 16px;
      }
    }

    & .footer {
      width: 100%;
      height: auto;
      display: flex;
      padding: 15px;
      flex-direction: column;
      padding-top: 130px;
      &__answer {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        & img {
          width: 70px;
          height: 40px;
        }

        & input {
          border-radius: 0;
          height: 40px;
          font-family: 'Poppins-SemiBold', serif;
          font-style: italic;
          background: #e3d1bc;
          display: inline-block;
          width: 150px;

          &::placeholder {
            color: #777;
            font-size: 12px;
          }

          &:focus {
            background: #e3d1bc;
            outline: 0;
            box-shadow: none;
            border: 0;
          }
        }

        &-button {
          width: 90px;
          height: 45px;
          background: url('../../Assets/Images/answer-board.png') no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;

          & h1 {
            text-align: center;
            color: $white;
            font-size: 12px;
            font-family: 'Cartwheel', serif;
            margin: 0;
            padding: 0 5px;
          }
        }
      }
    }
  }
}

.question-answered--style {
  color: #FF9908;
  margin-bottom: 8px;
}

.question-answered--count {
  color: #000;
  background: #BDBDBD;
}

.margin-top--80 {
  margin-top: 80px;
}

.proposal-summary__modal .modal-body .carousel {
  height: 650px !important;
}