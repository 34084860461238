@import 'src/Assets/variables';

.toggle-button {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 23px;

  & input { 
    opacity: 0;
    width: 0;
    height: 0;
         
  } 

  & input:checked + &-slider {
    background-color: $main;
  }

  & input:focus + &-slider {
    box-shadow: 0 0 1px #2196F3;
  }

  & input:checked + &-slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }


  
  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C4C4C4;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
        left: 2px;
      bottom: 2px;
      background-color: white;
      transition: .4s;
    }

    &.round {
      border-radius: 17px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}







