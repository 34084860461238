@import 'src/Assets/variables';

.project-list {
  width: 100%;
  min-height: 100px;
  padding: 30px 0;
  margin-top: 0;

  &-top-margin {
    margin-top: 180px;
  }

  &-items {
    display: flex;
    width: 100%;
    min-height: 260px;
    border-bottom: 1px solid #ccc;
    //border-radius: 20px;
    margin-bottom: 40px;

    &-images {
      width: 250px;
      min-height: 250px;
      border-radius: 20px 0 0 20px;
      display: flex;
      align-items: center;

      &.math {
        //background-color: #F79838;
      }

      &.science {
        //background-color: #1A2B4C;
      }
    }

    &-description {
      padding: 30px 30px 30px 30px;
      flex: 1;
      //border-bottom: 1px solid #CCC;

      &-text {
        cursor: pointer;
      }

      & h1 {
        font-family: 'Poppins-Bold', serif;
        font-size: 20px;
        margin: 0 0 20px 0;
        color: $main;
      }

      &-badge {
        display: flex;
        margin-top: 30px;
      }

      &-custom-badge {
        padding: 10px !important;
        border-radius: 10px;
        background: #eee;
        margin-right: 10px;
        font-weight: normal;
        font-family: 'Nunito-Regular', serif;
        text-transform: uppercase;
      }

      &-list {
        padding-left: 20px;
        list-style: none;

        li::before {
          content: '\2022';
          color: $secondary;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }

        span {
          color: $secondary;
        }
      }
    }

    &-info {
      display: flex;
      //justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 30px 30px 30px 30px;
      //border-bottom: 1px solid #CCC;

      & h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0 0 40px 0;
        font-family: 'Poppins-SemiBold', serif;
      }

      & p {
        padding: 2px 0;

        &.no-team {
          color: $ghost;

          & svg {
            color: $ghost;
          }
        }

        &.available-team {
          color: $secondary;

          & svg {
            color: $secondary;
          }
        }

        & svg {
          font-size: 18px;
          position: relative;
          top: -2px;
          color: $main;
        }
      }
    }
  }
}
