@import 'src/Assets/variables';

.contestant-proposal {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;

  &-overview {
    width: 100%;
    min-height: 300px;
    padding: 30px 0 30px 0;
    border-bottom: 1px solid $line;

    &-title {
      font-family: 'Poppins-SemiBold', serif;
      padding: 15px 0;

      & h1 {
        font-size: 24px;
        line-height: 36px;
        margin-top: 15px;
      }
    }

    &-modal {
      max-width: 600px;

      & .modal-content {
        padding: 15px 0;
        border-radius: 20px;
      }

      & .modal-header {
        border: 0;

        & svg {
          font-size: 48px;
        }
      }

      & .modal-title {
        width: 100%;
        font-size: 16px;
        font-family: 'Poppins-SemiBold', serif;
        text-align: center;
      }

      & .modal-body {

      }

      &-profile:nth-child(1) {
        border-top: 1px solid $ghost;
      }

      &-profile {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        border-bottom: 1px solid $ghost;
        justify-content: space-between;

        &-whatsapp {
          padding: 20px 0;

          & .custom-button .btn-primary {
            display: flex;
            padding: 10px 25px;

            & img {
              margin-right: 10px;
            }
          }
        }

        &-wrapper {
          width: 100%;
          display: flex;
        }

        &-avatar {
          width: 75px;
          height: 75px;
          border-radius: 100px;

          & img {
            max-width: 100%;
          }
        }

        &-info {
          padding: 0 15px;

          & .label-badge {
            display: inline-block;

            & .badge-light {
              padding: 5px 10px;
            }
          }

          & h2 {
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'Poppins-SemiBold', serif;
            margin-bottom: 5px;
            display: inline-block;
          }

          & p {
            font-size: 14px;
            color: $typography;
            font-weight: normal;
          }

          &-other {
            margin-top: -10px;
            font-size: 16px;
            font-weight: bold;
            font-family: 'Poppins-SemiBold', serif;
            color: $secondary;

            & svg {
              color: $secondary;
              position: relative;
              top: -3px;
            }
          }
        }
      }
    }

    &-member {
      width: 100%;
      display: flex;
      cursor: pointer;

      &-avatar {
        width: 64px;
        height: 64px;
        border-radius: 100px;
        border: 3px solid $white;

        &:not(:first-child) {
          margin-left: -8px;
        }

        & img {
          max-width: 100%;
        }
      }
    }

    &-content {
      & p {
        & .label-badge {
          display: inline-block;

          & .badge-light {
            padding: 12px 18px;
            font-size: 14px;
          }
        }
      }

      & h5 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        margin-top: 10px;
      }

      &-wrapper {
        border-bottom: 1px solid #EBEBEB;
        margin-bottom: 15px;
        padding: 10px 0;
      }
    }

    &-view-more {
      color: #E3B26A;
      font-weight: bold;
    }
  }

  &-jumbotron {
    width: 100%;
    min-height: 300px;
    background-position: bottom;
    background-size: cover;
    background-color: #ffffff;
    padding: 80px 0;
    margin-top: 140px;

    &.science {
      background-image: url('../../Assets/Images/science-page-bg.png');
      background-position: 50% 90%;
    }

    &.maths {
      background-image: url('../../Assets/Images/math-page-bg.png');
      background-position: 50% 90%;
    }

    &-detail {
      width: 850px;
      height: auto;
      margin-left: 5%;

      &-label {
        display: flex;

        & .label-badge {
          margin-right: 10px;
        }
      }

      & h1 {
        font-size: 28px;
        font-family: 'Poppins-SemiBold', serif;
        margin-top: 15px;
        line-height: 36px;
        color: $main-txt;
      }
    }
  }

  &-grey {
    background-color: #FAFAFA;
    padding-top: 40px;
    padding-bottom: 100px;
  }

  &-width-container {
    width: 80%;
    margin-left: 10%;
  }

  &-proposals-container {
    display: flex;
    flex-flow: row wrap;
  }

  &-tab {
    width: 100%;
    margin-top: -50px;

    & .nav-tabs {
      border: 0;
      margin-left: 10%;

      & .nav-link {
        color: $line;
        border: 0;
        font-family: 'Poppins-SemiBold', serif;
        padding: 0.5rem 3rem;

        &.active {
          color: $main-txt !important;
          border-bottom: 3px solid $main-txt !important;
        }
      }
    }
  }

  &-filter {
    width: 100%;
    min-height: 50px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    & .btn {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED;
      margin-right: 15px;
    }

    & .btn-primary {
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        color: #636363!important;
        border: 1px solid $main!important;
      }
    }

    & .btn-secondary {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED!important;
      margin-right: 15px;
      box-shadow: none;

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        color: #FFF!important;
        background: $main!important;
      }
    }

    & .btn-primary:hover {
      border: 1px solid $main !important;
    }

    & .btn-secondary:hover {
      color: #FFF !important;
      background: $main !important;
    }
  }

  &-deadline {
    width: 100%;
    margin-bottom: 60px;
    position: relative;

    &-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(calc(-50%));
      width: 80%;
      margin-bottom: 60px;
    }

    &-card {
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      height: 60px;
      padding: 18px 15px;
      border-radius: 100px;
      text-align: center;
      position: relative;
      background-color: $white;

      &-img {
        width: 80px;
        position: absolute;
        left: 5%;
        top: -15px;
      }
    }

    &-join .btn {
      height: 60px;
    }
  }

  &-guidelines {
    border-top: 1px solid #E0E0E0;
    padding-top: 40px;
    padding-bottom: 40px;

    &-left {
      &-sub {
        margin-left: 15px;
        font-size: 14px;
      }
    }

    &-right {
      padding-right: 0;
    };

    &-cards {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      &-card {
        width: 32%;
        background-color: $white;
        border-radius: 7px;
        position: relative;
        padding-top: 10px;
        padding-left: 2%;
        padding-bottom: 80px;

        -webkit-box-shadow:inset 0px 0px 0px 3px $main-txt;
        -moz-box-shadow:inset 0px 0px 0px 3px $main-txt;
        box-shadow:inset 0px 0px 0px 3px $main-txt;
        //border: 3px solid $main-txt;
        filter: grayscale(0%);

        &.active {
          -webkit-box-shadow:inset 0px 0px 0px 3px $main-txt;
          -moz-box-shadow:inset 0px 0px 0px 3px $main-txt;
          box-shadow:inset 0px 0px 0px 3px $main-txt;
          //border: 3px solid $main-txt;
          filter: grayscale(0%);
        }

        &-heading {
          color: $main-txt;
          font-weight: bold;
          display: block;
          font-size: 20px;
          z-index: 1;
          position: relative;

          &.small {
            font-size: 14px;
            z-index: 1;
            position: relative;
          }

        }

        &-circle-small {
          background-color: #F2F2F2;
          height: 50px;
          width: 100px;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
          position: absolute;
          bottom: 3px;
          right: 25%;
          z-index: -1;
        }

        &-circle-big {
          background-color: #F2F2F2;
          width: 100px;
          height: 100px;
          position: absolute;
          top: 3px;
          right: 3px;
          border-radius: 0 7px 0 100%;
          z-index: -1;
        }

        &-btn {
          position: absolute;
          top: calc(100% + 20px);
          width: 102%;
          left: -2%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        }

        .custom-button {
          button
          {
            font-size: 12px;
            padding: 0px;
            width: 97%;
            &:hover {
              background-color: $main;
              color: white;
            }
          }
        }

        &-text-example {
          display: block;
          color: $secondary;
          margin-top: 36px;
          font-size: 14px;
          z-index: 1;
          position: relative;
        }

        &-img {
          position: absolute;
          right: 5%;
          top: 11px;
          z-index: 0;
        }
      }
    }
  }

  &-gold-text {
    color: $secondary;
  }
}
