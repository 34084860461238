.tutorial-modal {
  & .modal-content {
    border: none;
    border-radius: 50px;
    padding: 10px;
  }

  & .carousel-indicators {
    bottom: -55px;

    & li {
      background-color: #fff;
    }

    & .active {
      background-color: black;
    }
  }
}