@import "src/Assets/variables";

.admin-edit-proposal {
  &-header {
    height: 100px;
    background-color: rgba(229, 229, 229, 0.2);
    display: flex;
    align-items: center;
    position: relative;

    & span {
      display: block;
    }

    &-arrow {
      font-size: 50px;
      margin-left: 5%;
      cursor: pointer;
    }

    &-title {
      font-family: "Poppins-SemiBold";
      font-size: 20px;
      display: inline-block;
      width: 38%;
      margin-left: 30px;
    }

    &-btn {
      position: absolute;
      right: 10%;
    }

    &-delete {
      position: absolute;
      right: 5%;
    }
  }

  &-title {
    padding-left: 10%;
    font-family: "Poppins-Bold", serif;
    display: flex;
    font-size: 28px;
    align-items: center;
    margin-top: 30px;

    & img {
      margin-right: 20px;
    }
  }

  &-lesson-content {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20px;

    .onoffswitch {
      position: relative;
      width: 65px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    .onoffswitch-checkbox {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    .onoffswitch-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid #999999;
      border-radius: 20px;
    }

    .onoffswitch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;

      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 30px;
        padding: 0;
        line-height: 30px;
        font-size: 14px;
        color: white;
        font-family: Trebuchet, Arial, sans-serif;
        font-weight: bold;
        box-sizing: border-box;
      }

      &:before {
        content: "ID";
        padding-left: 10px;
        background-color: #34a7c1;
        color: #ffffff;
      }

      &:after {
        content: "EN";
        padding-right: 10px;
        background-color: #eeeeee;
        color: #999999;
        text-align: right;
      }
    }

    .onoffswitch-switch {
      display: block;
      width: 18px;
      margin: 6px;
      background: #ffffff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 31px;
      border: 2px solid #999999;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }

    .onoffswitch-checkbox:checked + .onoffswitch-label {
      .onoffswitch-inner {
        margin-left: 0;
      }

      .onoffswitch-switch {
        right: 0px;
      }
    }
  }

  &-voice-over {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;

    &-img {
      align-self: flex-start;
    }

    &-labels {
      margin-left: 10px;
      width: 10%;

      &-main {
        font-family: "Poppins-Regular";
        font-weight: bold;
        color: $main-txt;
        // font-size: 18px;
      }

      &-sub {
        color: rgba(99, 99, 99, 0.8);
        display: block;
        font-size: 14px;
      }
    }

    &-fields {
      display: flex;
      justify-content: space-between;
      width: stretch;
      flex-grow: 1;
    }

    &-field {
      width: 48%;
      position: relative;

      &-lang {
        position: absolute;
        top: -50px;
        font-family: "Poppins-Bold";
        font-size: 30px;

        &.bahasa {
          // right: 0;
        }
      }
    }
  }

  &-audio {
    padding: 30px 0;
    border-bottom: 1px solid $ghost;
    display: flex;

    &-field {
      display: flex;
      justify-content: space-between;
      width: 48%;

      & .text-download {
        width: 92%;
      }

      & .custom-button {
        width: 95%;
      }

      &-container {
        width: 95%;
        display: flex;
        flex-direction: column;

        &-error {
          color: red;
          width: 95%;
          text-align: center;
        }
      }
    }

    & .filled-button {
      & .custom-button .btn {
        text-align: center !important;
        background-color: #ffdce1 !important;
        color: $main !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-input {
    height: 55px;
    border: 1px solid $main-txt;
    border-radius: 50px;
    color: $main-txt;
    font-weight: bold;
    padding-left: 5%;
    width: 70%;

    &:active,
    &:focus {
      outline: none;
    }

    &-btn {
      width: 55px;
      height: 55px;
      border-radius: 100%;
      background-color: $button-main;
      border: none;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &-questions {
  }
}
