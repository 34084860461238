@import 'src/Assets/variables';

.how-it-works {
  &-header {
    margin-top: 155px;
    background: url(../../Assets/Images/my-projects-background.png);
    text-align: left;
    padding-top: 127px;
    background-size: contain;
    background-repeat: no-repeat;

    &-heading {
      font-family: 'Poppins-Bold', serif;
      color: $button-main;
      font-size: 56px;
      padding: 20px 0;
    }

    &-sub {
      color: $typography;
      margin-bottom: 5px;
      font-size: 18px;
      line-height: 36px;
    }
  }

  &-footer {
    background-color: #FBFBFB;
    color: $typography;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url('../../Assets/Images/confetti.png');
    background-position: top;
    background-repeat: no-repeat;

    &-heading {
      font-family: 'Poppins-Bold', serif;
    }

    &-text {
      width: 60%;
      margin-left: 20%;
    }

    &-btn {
      width: 15%;
      margin-left: 42.5%;
      margin-top: 27px;
    }
  }

  &-stages {
    width: 70%;
    margin-left: 15%;
    display: flex;
    margin-top: 47px;

    &-stage {
      width: 25%;
      position: relative;
      font-family: 'Poppins-Regular', serif;
      font-weight: bold;
      color: $typography;
      cursor: pointer;

      &.active > &-num {
        background-color: $main-txt;
        color: $white;
        border: 1px solid $main-txt;

        & > .how-it-works-stages-stage-desc {
          color: $main-txt;
        }
      }


      &-num {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid #BDBDBD;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &.last.active {
          background-color: $main-txt;
          color: $white;
          border: 1px solid $main-txt;
          font-weight: bold;
        }
      }

      &-tail {
        position: absolute;
        width: calc(100% - 40px);
        height: 1px;
        background-color: $typography;
        top: 50%;
        left: 40px;

        &.active {
          background-color: $main-txt;
          height: 2px;
        }
      }

      &-desc {
        position: absolute;
        top: 60px;
        width: 500%;
        text-align: center;
        padding: 20px 0;
        border-radius: 50px;
        transition: all 0.250s;
      }

      &-desc.active {
        background-color: $main-txt;
        color: #fff !important;
      }
    }
  }

  &-step {
    &-heading {
      font-family: 'Poppins-Bold', serif;
      margin-top: 10px;
    }

    &-text {
      margin-top: 20px;
    }
  }

  &-testimonial {
    margin-top: 150px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      text-align: center;
      margin-bottom: 20px;
    }

    &-testimonial-wrapper {
      margin-top: 60px;
      border-radius: 5px;
      box-shadow: 5px 5px 12px 10px rgba(0, 0, 0, 0.04);
      width: 100%;
      min-height: 100px;
      background-color: #fff;

      .carousel-indicators {
        bottom: 30px;
      }

      .testimonial-item {
        padding: 100px 200px;

        .row {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        img.avatar {
          width: 100%;
          float: right;
        }

        &-blockquote {
          width: 100%;

          p {
            margin: 20px ;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
          }

          .first {
            float: left;
            position: absolute;
            top: 0;
          }

          .last {
            float: right;
            position: relative;
            bottom: 40px;
            right: 0;
          }

          h3 {
            margin-top: 50px;
            margin-bottom: 5px;
            font-family:'Poppins-Bold', serif ;
            color: #F2994A;
            font-weight: 700;
            font-size: 32px;
            line-height: 43px;
            text-align: left;
          }
        }
      }
    }
  }

  &-find-challenge {
    margin-top: 80px;

    &-left {
      padding-top: 30px;
    }
  }

  &-find-team {
    margin-top: 80px;

    &-img-container {
      position: relative;

      &-btn {
        position: absolute;
        width: 100%;
        top: 68%;
        left: -1%;
      }
    }

    &-right {
      padding-top: 60px;
    }
  }

  &-work-together {
    margin-top: 80px;

    & .btn {
      padding: 0px !important;
    }
  }

  &-requirements {
    margin-top: 80px;

    &-btn-container {
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }

    &-btn {
      margin-top: 40px;
      width: 32%;

      & .custom-button-full-width {
        height: 60px;
        font-size: 13px;

        padding: 0px !important;
      }
    }
  }

  &-accordion {


    &-heading {
      color: $main-txt;
      font-family: 'Poppins-Regular', serif;
      font-weight: bold;
      border-bottom: 1px solid #C4C4C4;
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 5%;
      cursor: pointer;

      &:first-of-type {
        border-top: 1px solid #C4C4C4;
      }

      &-pointer {
        color: $typography;
        font-size: 14px;
        margin-left: 5%;
      }
    }

    &-point {
      display: flex;
      align-items: center;
      margin-top: 20px;

      &-check {
        margin-right: 5%;
      }

      &-text {
        display: inline-block;
        width: 80%;
        margin-bottom: 0;
      }
    }
  }

  &-gold-text {
    color: $secondary;
  }

  &-bold {
    font-weight: bold;
  }

  &-blue-text {
    color: $main-txt;
  }

}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  margin-right: 5px;
  margin-left: 5px;
  text-indent: -999px;
  cursor: pointer;
  border: 2px solid $main-txt;
  opacity: 1;
  background: none;
  transition: opacity 0.6s ease;
}

.carousel-indicators li.active {
  background-color: $main-txt;
}

.carousel-control-next {
    right: 50px;

    &:hover {
      background-image: none;
    }
}

.carousel-control-prev {
    left: 50px;

    &:hover {
      background-image: none;
    }
}

.direction-button {
  background-color: $main-txt;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 45px;
}