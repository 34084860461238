.join-project-modal {

  & > .modal-content {
    border-radius: 20px;
    max-height: 90vh;
  }

  &-member {
    min-height: 50px;
    padding-top: 10px;
    margin-bottom: 0;
    cursor: pointer;
    width: 95%;
    margin-left: 2.5%;
  }

  &-deadline {
    box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.05);
    padding-top: 20px;
    padding-bottom: 20px;

    &-btn-flex {
      width: 95%;
      margin-left: 2.5%;
      display: flex;
      justify-content: space-between;


      &-btn {
        width: 49%;
      }
    }
  }
}