.scrollable-lesson {

}


.menu-item {
  padding: 0 40px;
  margin: 5px 15px;
  user-select: none;
  cursor: pointer;
  border: none;
  outline: 0;

  &-wrapper {
    border: none;
    outline: 0;

    &.active {
      border: none;
      outline: 0;
    }
  }

  &.active {
    border: none;
    outline: 0;
  }
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;

  &--disabled {
    visibility: hidden;
  }
}


