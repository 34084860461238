@import 'src/Assets/variables';

.lesson-request-detail {
  &-heading {
    &-sub {
      margin-top: 40px;

      &-arrow {
        margin-right: 20px;
      }
    }
  }

  &-title {
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    padding: 20px 0 20px 5%;
    position: relative;

    &-main {
      color: $main-txt;
      font-family: 'Poppins-Regular', serif;
      font-weight: bold;
    }

    &-labels {
      display: flex;
      margin-top: 10px;
    }

    &-label {
      margin-right: 0;
    }

    &-btn {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-ranked-team {
    border: 1px solid #F2F2F2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 15px 0 15px 3%;
    position: relative;

    &-rank {
      font-family: 'Poppins-Bold', serif;
      font-size: 32px;
      color: $typography;
    }

    &-heart {
      font-size: 22px;
      margin-left: 3%;
      color: $secondary;
      cursor: pointer;
    }

    &-name {
      font-family: 'Poppins-SemiBold', serif;
      color: $main-txt;
      margin-left: 3%;
      font-size: 18px;
    }

    &-label {
      position: absolute;
      right: 50%;
    }

    &-members {
      position: absolute;
      right: 25%;
    }

    &-avatar {
      width: 50px;
      border-radius: 100%;
      border: 3px solid $white;
      position: relative;

      &.span {
        display: inline-block;
        background-color: #BDBDBD;
        font-family: 'Poppins-Regular', serif;
        font-weight: bold;
        color: #FFFFFF;
        font-size: 20px;
        text-align: center;
        padding-top: 10px;
        left: -40px;
        top: 5px;
        z-index: 3;
        height: 50px;
      }

      &:nth-of-type(2) {
        left: -10px;
        z-index: 1;
      }

      &:nth-of-type(3) {
        left: -25px;
        z-index: 2;
      }

      &:nth-of-type(4) {
        left: -40px;
        z-index: 3;
      }

    }

    &-btn {
      position: absolute;
      right: 10%;
    }

    &-grab {
      width: 25px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      position: absolute;
      right: 3%;
      cursor: grab;

      & img {
        width: 100%;
        margin-top: 5px;
      }
    }
  }

  &-final-ranking {
    padding-bottom: 20px;
    border-bottom:  1px solid #C4C4C4;
    width: 90%;
    margin-left: 5%;

    &-heading {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &-text {
        font-family: 'Poppins-Regular', serif;
        font-weight: bold;
        color: $typography;
        font-size: 20px;
      }
    }
  }

  &-other-lesson {
    &-heading {
      color: $typography;
      font-family: 'Poppins-SemiBold', serif;
      font-weight: bold;
      margin-left: 5%;
      display: block;
      margin-top: 10px;
    }
  }

  &-other-lesson-cards {
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    margin-left: 5%;
  }

  &-other-lesson-card {
    border: 1px solid #F2F2F2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    width: 31%;
    position: relative;
    padding: 10px 0 20px 2%;
    margin-right: 2%;
    margin-top: 20px;


    &-heading {
      color: $main-txt;
      font-family: 'Poppins-Regular', serif;
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
    }

    &-heart {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 24px;
      color: #E0E0E0;
      cursor: pointer;
    }

    &-label {
      margin-bottom: 15px;
    }


    &-btn {
      position: absolute;
      right: 5px;
      bottom: 25px;
    }
  }
}
