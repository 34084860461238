@import 'src/Assets/variables';

$width: 95%;
$leftMargin: 2.5%;

.start-proposal-modal {

  &-loading {
    padding: 20px;
    text-align: center;
  }

  & > .modal-content {
    border-radius: 20px;
    max-height: 90vh;
  }

  &-header {
    width: $width;
    margin-left: $leftMargin;
    border-bottom: 1px solid #828282;
    margin-bottom: 5px;
    font-family: 'Poppins-Bold', serif;
    padding-left: 5%;
    padding-top: 40px;
    padding-bottom: 10px;

    & h1 {
      font-family: 'Nunito-Regular', serif;
    }

    & .creative-work__deadline {
      margin-left: 0px;
    }

    &-close {
      position: absolute;
      right: 7%;
      top: 20px;
      cursor: pointer;
      z-index: 1;
    }

    &-team-name {
      color: $typography;
    }

    &-lesson {
      margin-top: 5px;
    }

    &-days {
      font-family: 'Nunito-Regular', serif;
      font-size: 14px;
      margin-top: 5px;
    }

    &-img {
      &-scope {
        position: absolute;
        right: 2%;
        top: 0;
        height: 118px;
        filter: grayscale(100%);
        opacity: 0.3;
      }

      &-atoms {
        position: absolute;
        right: 25%;
        top: 5px;
      }
    }
  }

  &-scrollable {
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-member {
    min-height: 50px;
    padding-top: 10px;
    margin-bottom: 0;
    cursor: pointer;
    border-top: 1px solid #E0E0E0;
    width: 95%;
    margin-left: 2.5%;
  }

  &-toggle {
    width: $width;
    margin-left: $leftMargin;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #f2f2f2;
    height: 42px;
    font-family: 'Nunito-Regular', serif;
    color: #000000;
    font-size: 12px;

    &-btn {
      margin-top: 7px;
    }
  }

  &-member {
    &-profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 20px;
      padding-top: 10px;
      border-bottom: 1px solid rgba(196, 196, 196, 0.6);
      position: relative;
      padding-left: 10px;

      &:hover {
        background-color: #f2f2f2;
      }

      &-avatar {
        width: 75px;
        height: 75px;
        border-radius: 100px;
        position: relative;

        &-img {
          width: 100%;
          height: 100%;
        }

        &-tick {
          position: absolute;
          right: -2px;
          bottom: -6px;
          width: 30px;
        }
      }

      &-close {
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
      }

      &-info {
        color: $typography;
        margin-left: 3%;

        &-sub {
          font-size: 13px;
        }

        &-name {
          font-weight: bold;
        }

        &-accept-text {
          width: 75%;
          padding-top: 15px;
        }
      }

      &-info-flex {
        display: flex;
        align-items: center;

        & h2 {
          font-size: 14px;
          text-transform: uppercase;
          font-family: 'Nunito-Regular', serif;
          margin-bottom: 5px;
          margin-right: 10px;
          padding-top: 8px;
          font-weight: bold;
        }
      }

      &-whatsapp {
        &-btn {
          background-color: $main !important;
          color: #FFFFFF !important;
          font-size: 12px;
          height: 39px;
          width: 30%;
          border-radius: 100px;
          border: none;
          font-weight: bold;
          font-family: 'Nunito-Regular', serif;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 11%;
          top: 33px;

          &:focus,
          &:active {
            outline: none;
          }

          & i {
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &-tabs {
    display: flex;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 20px;

    &-tab {
      width: 50%;
      cursor: pointer;
      padding-bottom: 20px;
      text-align: center;
      border-bottom: 4px solid $ghost;
      font-family: 'Poppins-Regular', serif;
      font-weight: bold;
      color: $ghost;
      font-size: 14px;

      &.active {
        border-bottom: 4px solid $main-txt;
        color: $main-txt;
        font-weight: bold;
      }

      &-received {
        &-empty {
          text-align: center;
        }

        &-btn {
          background-color: $main;
          color: #FFFFFF;
          font-size: 14px;
          height: 39px;
          width: 20%;
          border-radius: 100px;
          border: none;
          font-weight: bold;
          font-family: 'Nunito-Regular', serif;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 11%;
          top: 33px;

          &:focus,
          &:active {
            outline: none;
          }
        }
      }

      &-requested {
        &-invite {
          color: #E3B26A;
          padding-left: 40px;
          margin-top: 20px;
          font-size: 13px;
          font-family: 'Nunito-Regular', serif;
          font-weight: bold;
          cursor: pointer;

          & i {
            font-size: 20px;
            margin-right: 10px;
          }
        }

        &-btn {
          @extend .start-proposal-modal-tabs-tab-received-btn;
          background-color: $white;
          border: 1px solid $ghost;
          color: $typography;

          &:hover {
            color: $typography;
            border: 1px solid $ghost;
            background-color: $ghost;
          }

          &:focus,
          &:active {
            outline: none;
          }
        }

        &-close {
          position: absolute;
          right: 5%;
          top: 38px;
        }
      }
    }
  }

  &-deadline {
    box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.05);
    padding-top: 20px;
    padding-bottom: 10px;

    &-btn {
      width: 90%;
      margin-left: 5%;
    }

    &-text {
      text-align: center;
      font-family: 'Nunito-Regular';
      font-weight: bold;
      color: $typography;
      font-size: 13px;
      margin-top: 10px;

      & span {
        color: #E3B26A;
        cursor: pointer;
      }
    }
  }

  &-undo {
    font-family: 'Nunito-Regular', serif;
    font-weight: bold;
    color: $secondary;
    font-size: 14px;
    position: absolute;
    right: 10%;
  }

  &-team-remove {
    &-overlay {
      background-color: rgba(0, 0, 0, .3);
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 10;
    }

    &-modal {
      position: fixed;
      background-color: #FFFFFF;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 15;
      width: 30%;
      border-radius: 20px;
      padding-top: 40px;
      padding-bottom: 20px;
      color: $typography;

      &-close {
        position: absolute;
        right: 5%;
        top: 15px;
        cursor: pointer;
      }

      &-heading {
        width: 80%;
        margin-left: 10%;
        text-align: center;
        font-family: 'Poppins-Regular', serif;
        font-weight: bold;

        & > span {
          color: $main-txt;
        }
      }

      &-sub {
        font-size: 12px;
        margin-top: 5px;
        width: 85%;
        margin-left: 7.5%;
        text-align: center;
        font-family: 'Nunito-Regular', serif;

      }

      &-avatar {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 20px;
      }

      &-btn {
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin-left: 2.5%;
        margin-top: 30px;
      }

      &-tags {
        font-size: 13px;
        text-align: center;
      }

      &-achievements {
        margin-top: -10px;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Poppins-SemiBold', serif;
        color: #E3B26A;
        text-align: center;
      }
    }
  }
}
