@import 'src/Assets/variables';

.invitation-card {
  width: 100%;
  height: 275px;
  border: 1px solid $ghost;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  padding: 25px;
  position: relative;

  &-math-bg {
    position: absolute;
    right: 7%;
    top: 0;
    filter: grayscale(100%);
    opacity: .5;
  }

  &__wrapper {

  }

  &__label {
    margin-bottom: 15px;
  }

  &__title {
    margin-bottom: 15px;

    & h1 {
      font-size: 20px;
      font-family: 'Poppins-SemiBold', serif;
      line-height: 36px;
      margin-bottom: 0;
    }
  }

  &__view {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;

    & a {
      color: $secondary;
      text-decoration: none;
    }

    & svg {
      position: relative;
      top: -2px;
      color: $secondary;
      font-size: 18px;
    }
  }

  &__deadline {
    display: inline-block;

    & svg {
      position: relative;
      top: -2px;
      color: $main-txt;
      font-size: 18px;
    }
  }

  &__member {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 20px;
    right: 15px;

    & .avatar {
      width: 56px;
      height: 56px;
      border-radius: 100px;
      border: 3px solid $white;
      position: relative;

      &:not(:first-child) {
        margin-left: -8px;
      }

    }

    &-img {
      max-width: 100%;
      border-radius: 100%;
    }
  }

  &__button {
    position: absolute;
    width: 90%;
    bottom: 10px;
  }
}
