@import 'src/Assets/variables';

.view-proposal-modal {
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $typography;
    font-family: 'Poppins-Regular';
    font-weight: bold;
    font-size: 24px;

    &-members {
      cursor: pointer;
    }
  }

  &-content {
    height: 300px;
    background-color: #C4C4C4;
    margin-top: 60px;
  }

  &-tally {
    color: $typography;
    display: block;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 40px;
  }

  &-btn {
    width: 50%;
    margin-left: 50%;
    transform: translateX(-50%);

    &-heart {
      font-size: 24px;
      color: $secondary;
    }

    &-text {
      font-size: 18px;
      color: $typography;
    }
  }
}