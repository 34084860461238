@import 'src/Assets/variables';

.admin-create-lesson {
  padding-bottom: 120px;


  &-header {
    margin-bottom: 80px;
    clear: both;
    background-color: rgba(242, 242, 242, .2);
    padding-top: 40px;
    padding-bottom: 60px;

    &-arrow {
      font-size: 40px;
      margin-left: calc(10% - 61px);
      margin-right: 20px;
      cursor: pointer;
    }

    &-text {
      font-family: 'Poppins-Bold';
      font-weight: bold;
      color: $main-txt;
      font-size: 34px;
    }

    &-img {
      float: right;
      margin-right: 5%;
      width: 80px;
      margin-top: -15px;
    }
  }

  &-form-heading {
    font-family: 'Poppins-Regular';
    font-weight: bold;
    color: $typography;
    font-size: 28px;
    display: block;
    margin-bottom: 20px;
  }

  &-headline-form {
    width: 80%;
    margin-left: 10%;
    border-bottom: 1px solid #828282;
    padding-bottom: 20px;
  }

  &-form-field {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    &-label {
      font-family: 'Poppins-Regular';
      font-weight: bold;
      color: $typography;
      display: block;

      &-sub {
        color: $typography;
        display: block;
      }
    }

    &-textarea {
      border: 1px solid #BDBDBD;
      border-radius: 10px;
      resize: none;

      &::placeholder {
        color: $line;
      }

      &-container {
        width: 60%;

        & span {
          top: calc(100% - 27px) !important;
          left: calc(100% - 48px) !important;
          color: $typography;
        }
      }
    }

    &-dropdown-container {
      display: flex;
      width: 60%;
      justify-content: space-between;
      margin-top: -25px;
    }

    &-switch {
      &-container {
        width: 60%;
      }

      // position: relative;
      // float: right;
      margin-top: 10px;
  
      .onoffswitch {
        position: relative;
        width: 65px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
      
      .onoffswitch-checkbox {
        position: absolute;
        opacity: 0;
        pointer-events: none;
      }
      
      .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid #999999;
        border-radius: 20px;
      }
      
      .onoffswitch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
      
        &:before, &:after {
          display: block;
          float: left;
          width: 50%;
          height: 30px;
          padding: 0;
          line-height: 30px;
          font-size: 14px;
          color: white;
          font-family: Trebuchet, Arial, sans-serif;
          font-weight: bold;
          box-sizing: border-box;
        }
      
        &:before {
          content: "YES";
          padding-left: 7px;
          background-color: $secondary;
          color: #FFFFFF;
        }
      
        &:after {
          content: "NO";
          padding-right: 10px;
          background-color: #EEEEEE;
          color: #999999;
          text-align: right;
        }
      }
      
      .onoffswitch-switch {
        display: block;
        width: 18px;
        margin: 6px;
        background: #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 31px;
        border: 2px solid #999999;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
      }
      
      .onoffswitch-checkbox:checked + .onoffswitch-label {
        .onoffswitch-inner {
          margin-left: 0;
        }
      
        .onoffswitch-switch {
          right: 0px;
        }
      }
    }
  }

  &-date {
    border: 1px solid #BDBDBD;
    width: 29%;
    margin-right: 31%;
    height: 45px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $line;
    padding-left: 15px;
    font-size: 14px;
    position: relative;

    &-icon-bg {
      background-color: $button-main;
      height: 100%;
      color: $white;
      width: 15%;
      border-radius: 0 10px 10px 0;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &-text {
      &.active {
        color: $typography;
      }
    }
  }

  &-detail-form {
    width: 80%;
    margin-left: 10%;
    border-bottom: 1px solid #828282;
    padding-bottom: 20px;
    margin-top: 40px;
  }

  &-btn {
    margin-top: 30px;
    clear: both;

    &-delete {
      float: right;
      margin-right: 2%;
      width: 14%;
    }

    &-publish {
      float: right;
      margin-right: 10%;
      width: 19%;
    }
  }

  &-dropdown {
    width: 32%;
  }
  &-dropdown-2 {
    width: 50%;
  }
}