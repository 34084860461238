@import 'src/Assets/variables';

.tab-player {
  margin: 0;
  overflow: hidden;
  border-radius: 10px;

  .col {
    padding: 10px 20px;
    text-align: center;
    border-top: 5px solid #e2e2e2;
    cursor: pointer;
    flex-grow: 0;
    flex-basis: unset;
    width: 25%;
    background-color: #e2e2e2;
  }

  .col:nth-last-child(2) {
    border-top-right-radius: 5px;
  }

  .col.active {
    background-color: #f2f2f2;
    border-color: $main;
    color: $main;
    cursor: default;
  }

  .player {
    background-color: #f2f2f2;
    padding: 10px;
    width: 100%;
  }
}