.recommendation {
  border-top: 1px solid #e0e0e0;
  padding: 28px 0px;
  display: flex;

  &-avatar {
    margin-right: 25px;
  }

  &-details {
    width: 40%;
    margin-right: 25px;

    & h3 {
      font-family: 'Poppins-Bold', serif;
      size: 20px;
      line-height: 30px;
      color: #636363;
    }

    & p {
      font-family: 'Nunito-Regular', serif;
      font-weight: normal;
      size: 16px;
      line-height: 25px;
      color: #636363;
    }
  }

  &-contents {
    &-rating {
      display: flex;
      margin: 5px;

      &-non-active {
        font-size: 30px;
        color: #c4c4c4;
        border-radius: 2px;
        margin: 0px 5px;
      }

      &-active {
        font-size: 30px;
        color: #e3b26a;
        border-radius: 2px;
        margin: 0px 5px;
      }

      & p {
        margin-left: auto;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #636363;
      }
    }

    &-button {
      margin: 5px;

      & button {
        background: rgba(229, 229, 229, 0.6);
        border-radius: 5px;
        border: none;
        cursor: default;
        margin: 5px;
      }
    }

    &-testimonial {
      padding: 5px 10px;
    }
  }
}