@import 'src/Assets/variables';

.custom-button {
  display: inline-block;

  & .btn-primary:focus, & .btn-primary.focus {
    box-shadow: none;
  }

  & .disabled.btn-primary {
    background: #636363 !important;
  }

  & .dropdown {
    & svg {
      position: relative;
      top: -2px;
      font-size: 18px;
      margin-right: 5px;
    }

    & .dropdown-toggle::after {
      display: none;
    }
  }

  & .btn {
    font-size: 14px;
    padding: 10px 35px;
    border-radius: 100px;
    border: none;
    font-weight: bold;
    font-family: 'Poppins-Regular', serif;
  }



  & .btn-primary {
    background-color: $button-main !important;
    color: $white !important;

    &:hover {
      color: $white;
      background-color: $main-txt;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: $main;
      color: $white;
      border-color: transparent;
    }
  }

  & .btn-secondary {
    background-color: $white;
    border: 1px solid $ghost;
    color: $typography;

    &:hover {
      // color: $typography;
      border: 1px solid $ghost;
      background-color: $ghost;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: $ghost;
      color: $typography;
      border-color: transparent;
    }
  }

  & .btn-light {
    background-color: transparent;
    color: $secondary;

    &:hover {
      background-color: transparent;
      color: $secondary;
      text-decoration: underline;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: transparent;
      color: $secondary;
      border-color: transparent;
    }
  }

  &-full-width {
    width: 100%;
    height: 50px;
  }
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}
