@import 'src/Assets/variables';

.complete-profile {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.4) -11.11%, rgba(255, 255, 255, 0) 63.44%), #F2767E;

  &-left-section {
    display: flex;
    padding: 30px 0;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-right-section {
    display: flex;
    padding: 30px;
    justify-content: center;
  }

  &-round{
    display: flex;
    width: 575px;
    height: 575px;
    border-radius: 50%;
    background: rgba(237, 249, 253, 0.4);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  &-illustration {
    display: flex;
    position: absolute;
    flex-shrink: 0;
    flex-grow: 0;
    bottom: 0;
    right: 0;
  }

  &-greetings h1{
    font-family: 'Poppins-Bold', serif;
    color: #000;
    font-size: 40px;
    line-height: 60px;
  }

  &-greetings p {
    font-family: 'Poppins-Bold', serif;
    color: #000;
    font-size: 16px;
    line-height: 50px;
  }

  &-form {
    width: 550px;
    height: 800px;
    background: #FFF;
    border-radius: 40px;
    padding: 60px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus {
      border: 1px solid $main;
      -webkit-text-fill-color: #000;
      -webkit-box-shadow: 0 0 0px 1000px #FFDCE1 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &-error {
      font-size: 14px;
      margin-bottom: 25px;
      color: #EB5757
    }

    &-title {
      font-family: 'Poppins-Bold', serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      color: #F2767E;
      margin-bottom: 25px;
    }

    &-picker {
      display: flex;
      justify-content: space-around;
      align-items: center;

      &-text {
        margin: 20px 0;
        color: #CCC;
      }

      & img {
        filter: grayscale(100%);
      }

      &-teacher, &-designer {
        cursor: pointer;
      }

      &-teacher:hover img, &-designer:hover img {
        filter: none;
        transition: 0.3s ease;
      }

      &-teacher:hover p, &-designer:hover p {
        color: #E3B26A;
        transition: 0.3s ease;
      }

      &-active img{
        filter: none
      }

      &-active p{
        color: #E3B26A;
      }
    }

    &-select {
      &:focus {
        border: 1px solid #000 !important;
      }

      &__option {
        &--is-selected {
          background: none !important;
          color: $main-txt !important;
          font-weight: bold !important;
        }
    
        &--is-focused{
          background: #F2767E !important;
          color: $white !important;
        }
      }

      &__multi-value {
        &__label {
          background: #FFDCE1 !important;
        }
        &__remove {
          background: #FFDCE1 !important;
        }
      }

      &__control {
        height: 50px;
        border-radius: 10px !important;
        border: 1px solid #ced4da;
        font-size: 14px;
        padding: 0 5px;

        &:focus {
          box-shadow: none;
          border: 1px solid #000;
        }
      }

      &__placeholder {
        color: $line !important;
      }
    }

    &-input-control {
      font-size: 14px;
      height: 50px;
      padding: 0 15px;
      border-radius: 10px;

      &::placeholder {
        color: #BDBDBD;
      }

      &:focus {
        outline: 0;
        border: 1px solid $main;
        box-shadow: none;
      }

      & .filled {
        background-color: #FFDCE1 !important;
      }
    }

    &-input-label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-input-after {
      padding: 15px 0;
    }

    &-input-label-checkbox {
      font-size: 14px;
    }

    &-input-terms-policy {
      font-size: 14px;
      color: #E3B26A;
      font-weight: bold;

      &:hover{
        color: #E3B26A;
        text-decoration: none;
      }
    }

    &-input-button {
      width: 100%;
      height: 50px;
      border-radius: 30px;
      background: #F2767E;
      outline: 0;
      border: 0;
      font-family: 'Poppins-Bold', serif;
    }

    &-input-button:disabled {
      background: #F2767E;
    }

    &-input-button:active {
      background: #F2767E !important;
      box-shadow: none !important;
    }

    &-input-button:focus {
      background: #F2767E !important;
      box-shadow: none !important;
    }

    &-input-skip {
      text-align: center;
      padding: 10px 0;

      & a {
        color: $main-txt;
        font-size: 14px;
      }
    }

    &-input-button:hover {
      background: #F2767E;
    }

    &-input-link {
      font-size: 14px;
      padding: 20px 0;
      color: #ADADAD;

      &-register {
        color: #E3B26A;
        font-weight: bold;
      }

      &-register:hover{
        color: #E3B26A;
        text-decoration: none;
      }
    }

    & .phone-input {
      &-country-code {
        background-color: $main;
        color: #fff;
      }

      &-number {
        width: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-left: 0px;
        padding-left: 5px;

        & .filled {
          background-color: #FFDCE1;
        }
      }
    }
  }
}

.tagify {
  padding: 8px 5px !important;
}

.tags-look .tagify__dropdown__item{
  display: inline-block;
  border-radius: 3px;
  padding: .3em .5em;
  border: 1px solid #CCC;
  background: #F3F3F3;
  margin: .2em;
  font-size: .85em;
  color: black;
  transition: 0s;
}

.tags-look .tagify__dropdown__item--active{
  color: black;
}

.tags-look .tagify__dropdown__item:hover{
  background: lightyellow;
  border-color: gold;
}

.error-complete-profile {
  font-size: 14px;
  margin-bottom: 15px;
  color: #EB5757
}
