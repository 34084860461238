@import 'src/Assets/variables';

.textarea {
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 10px;
  resize: none;

  &::placeholder {
    color: $line;
  }

  &-container {
    position: relative;
  }

  &-length {
    position: absolute;
    right: 15px;
    bottom: 5px;
    color: #C4C4C4;
    font-weight: bold;
    font-size: 12px;
  }
}