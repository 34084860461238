@import 'src/Assets/variables';

.creative-work {
  width: 100%;
  height: 275px;
  border: 1px solid $ghost;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  padding: 25px;
  position: relative;
  color: $typography;
  cursor: pointer;

  &:hover .creative-work__title a{
    text-decoration: underline;
  }

  &-img-bg {
    position: absolute;
    right: 7%;
    top: 0;
    filter: grayscale(100%);
    opacity: 0.2;
    z-index: -1;
  }

  &__wrapper {

  }

  &__label {
    margin-bottom: 15px;
  }

  &__title {
    margin-bottom: 15px;
    position: relative;

    & a {
      color: $another;
    }

    & h1 {
      font-size: 16px;
      font-family: 'Poppins-Bold', serif;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &__title--margin-50 {
    margin-bottom: 10px;
  }

  &__ribbon {
    width: 150px;
    height: 50px;
    background: url('../../Assets/Images/cw-card-ribbon.png') center no-repeat;
    background-size: contain;
    position: absolute;
    right: -15px;
    padding: 12px;

    & span {
      color: $white;
      font-family: 'Poppins-SemiBold', serif;
      text-transform: uppercase;
      position: absolute;
      right: 40px;
      font-size: 14px;
    }
  }

  &__progress-bar {
    margin-top: 20px;

    & .percent {
      position: relative;
      top: -5px;
      left: -15px;
    }

    & .progress {
      border-radius: 100px;
    }

    & .progress-bar {
      border-radius: 100px;
      background-color: $main;
    }
  }

  &__deadline {
    font-family: 'Poppins-SemiBold', serif;
    font-size: 12px;
    margin-left: 15px;
    & svg {
      position: relative;
      top: -2px;
      color: $main-txt;
    }
  }

  &__member {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    & .avatar {
      width: 56px;
      height: 56px;
      border-radius: 100px;

      &:not(:first-child) {
        margin-left: -12px;
      }

      // & img {
      //   max-width: 100%;
      // }
    }

    &-img {
      max-width: 100%;
      border-radius: 100%;
      border: 3px solid $white;
      margin-left: -3px;
    }
  }

  &__member--flex-end {
    justify-content: flex-end;

    &.up {
      position: absolute;
      bottom: 45px;
      right: 5%;
    }
  }

  &__button {
    display: flex;
    padding: 10px 0;
  }

  &__button--flex-end {
    justify-content: flex-end;
  }

  &-bottom-row {
    padding-top: 15px;
  }
}

.organization-card {
  &-before-lock-team {
    &-btn {
      position: absolute;
      bottom: 25px;
      right: 5%;
    }
  }

  // &-creative-work-member {
  //   position: absolute;
  //   right: 5%;
  //   bottom: 30px;
  //   width: 30%;
  //   display: flex;
  // }
}
