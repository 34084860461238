@import 'src/Assets/variables';

.review-lesson-modal {

  &-title {
    font-family: 'Poppins-Bold';
    color: $typography;
    display: block;
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    padding-left: 35px;

    // Toggle Button Styles
    &-toggle-button {
      position: relative;
      width: 39px;
      height: 23px;
      float: right;
      margin-top: 10px;
    
      & input { 
        opacity: 0;
        width: 0;
        height: 0;
             
      } 
    
      & input:checked + &-slider {
        background-color: $secondary;
      }
    
      & input:focus + &-slider {
        box-shadow: 0 0 1px $secondary;
      }
    
      & input:checked + &-slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
      }
    
    
      
      &-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #C4C4C4;
        -webkit-transition: .4s;
        transition: .4s;
    
        &:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
            left: 2px;
          bottom: 2px;
          background-color: white;
          transition: .4s;
        }
    
        &.round {
          border-radius: 17px;
    
          &:before {
            border-radius: 50%;
          }
        }
      }
    }
    // Toggle Button Styles End

  }

  &-sub-text {
    display: block;
    font-family: 'Poppins-Regular';
    font-weight: bold;
    color: $typography;
    text-align: center;
    margin-top: 5px;

    &-small {
      display: block;
      text-align: center;
      color: $typography;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &-bottom-text {
    margin-top: 40px;
    display: flex;
    font-weight: bold;
    color: $typography;
    align-items: center;
    justify-content: space-between;

    &-dot {
      // display: inline-block;
      width: 3px;
      height: 3px;
      background-color: $typography;
      border-radius: 100%;
      margin-left: 10px;
      margin-right: 10px;
      // margin-top: -10px;
    }
  }
}