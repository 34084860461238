.mobile-device {
    // align-items: center;
    margin-top: 25%;
    // height: 100vh;
  
    & h1 {
      font-size: 1.5rem;
    }

    & p {
      // margin-bottom: 0px;
    }
  
    & .video-container {
      & .player-wrapper {
        position: relative;
        padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
      }
      
      & .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }