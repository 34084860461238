@import 'src/Assets/variables';

.edit-profile {

  &-width {
    width: 80%;
    margin-left: 10%;
  }

  &-header {
    margin-top: 150px;
    font-weight: bold;
    font-family: 'Poppins-Bold', serif;
    text-align: center;
  }

  &-tabs {
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    justify-content: space-evenly;
    font-family: 'Poppins-Bold', sans-serif;
    font-size: 20px;
    margin-top: 25px;

    &-tab {
      padding-bottom: 20px;
      cursor: pointer;
      color: #E0E0E0;
      width: 33%;
      text-align: center;

      &.active {
        color: $button-main;
        border-bottom: 3px solid $button-main;
      }

    }

    &-content-container {
      padding-bottom: 60px;
    }

  }

  &-personal-info {
    &-img-container {
      display: flex;
      flex-flow: column;
      align-items: center;

      &-img {
        width: 150px;
        margin-top: 30px;
        margin-bottom: 20px;
        border-radius: 100%;
      }

      &-upload-btn-img {
        margin-right: 10px;
      }
    }

    &-btn-save {
      width: 25%;
      margin-top: 30px;
    }
  }

  &-form-heading {
    font-family: 'Poppins-Regular', serif;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &-btn {
    &-outline-primary {
      background-color: $white;
      border: 1px solid $main !important;
      color: $main;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $main;
        color: $white;
      }

      &-icon {
        font-size: 30px;
        margin-right: 10px;
      }
    }
  }

  &-social-profile {
    &-btn {
      &-update {
        margin-top: 30px;
        width: 35%;
      }
    }
  }

  &-account-setting {
    &-input {
      &-password {
        letter-spacing: 20px;
        font-size: 22px;
        color: #C4C4C4;
      }
    }

    &-flex-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-btn-password {
      margin-top: 10px;
    }

    &-btn-update {
      width: 30%;
      margin-top: 40px;
    }
  }

  &-img-file-input {
    display: none;
  }

  &-modal {
    & .modal-content {
      max-height: 95vh;
      padding-bottom: 20px;
      border-radius: 20px;
    }

    &-header {
      &-sub {
        font-size: 12px;
        font-family: 'Poppins-Regular';
        margin-top: 4px;
      }
    }

    &-img-container {
      width: 95%;
      margin-left: 2.5%;
      padding-bottom: 10px;
      border-bottom: 1px solid #828282;
    }

    &-img-container > img {
      width: 100%;
    }

    &-btn {
      width: 95%;
      margin-left: 2.5%;
      margin-top: 20px;
    }
  }
}
