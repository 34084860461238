@import 'src/Assets/variables';

.navigation-bar {
  width: 100%;
  height: 150px;
  background: #FFF !important;
  position: fixed;
  top: 0;
  z-index: 2;

  &-logo {
    width: 111px;
  }

  & .navbar {
    background: #FFF !important;
  }

  &-navbar-option {
    &.active {
      color: $main-txt !important;
    }
  }

  &-sign-in {
    color: #E3B26A !important;
    font-weight: bold !important;
    text-decoration: none;
  }

  &-sign-up {
    // background: #A2DAF1 !important;
    background-color: $main;
    font-weight: bold !important;
    text-decoration: none;
    border-radius: 100px;
    color: #FFF !important;
  }
}

.navbar-light .navbar-nav .nav-link {
  padding: 10px 25px !important;
  font-weight: bold !important;
}
