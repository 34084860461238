.start-proposal-alone-confirm-modal {

  & > .modal-content {
    border-radius: 20px;
    padding-bottom: 20px;
  }


  &-close {
    position: absolute;
    right: 5%;
    top: 20px;
    cursor: pointer;
  }

  &-heading {
    font-family: 'Poppins-Regular', serif;
    font-weight: bold;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }

  &-sub {
    font-size: 14px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
  }

  &-btn-flex {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;

    &-btn {
      width: 47%;
    }
  }
}
