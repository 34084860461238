@import 'src/Assets/variables';

.open-to-anyone-modal {
  &-content {
    text-align: center;

    &-image {
      margin-top: -40px;
      margin-bottom: 20px;
    }

    &-text {
      margin-bottom: 50px;

      & h4 {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #636363;
      }

      & p {
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: #636363;
      }
    }

    &-invitation {
      &-link {
        color: #e3b26a;

        &:hover {
          color: #e3b26a;
          text-decoration: none;
        }

        &:visited {
          color: #e3b26a;
          text-decoration: none;
        }
      }
    }

    &-submit {
      display: flex;

      &-button {
        width: 100%;
        margin: 5px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 35px;
        padding: 15px 60px;
        outline: none;

        &:focus {
          outline: none;
        }

        &-primary {
          background: $main;
          color: #ffffff;
          width: 100%;
          margin: 5px;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 35px;
          padding: 15px 60px;
          outline: none;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  & .modal-content {
    padding: 15px 0;
    border-radius: 20px;
  }

  & .modal-header {
    border: 0;

    & svg {
      font-size: 48px;
    }
  }

  & .modal-title {
    width: 100%;
    font-size: 16px;
    font-family: 'Poppins-SemiBold', serif;
    text-align: center;
  }

  & .modal-body {
    & .tab-content {
      padding: 30px 0 0 0;
    }

    & .nav-tabs {
      border: 0;

      & .nav-link {
        color: $line;
        border: 0;
        font-family: 'Poppins-SemiBold', serif;
        width: 50%;
        text-align: center;

        &.active {
          color: $main-txt !important;
          border-bottom: 3px solid $main-txt !important;
        }
      }
    }
  }
}
