@import 'src/Assets/variables';

.profile-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;

  &-button {
    width: 220px;
    height: 50px;
    border: 1px solid $ghost;
    border-radius: 100px;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;

    &-active {
      border: 1px solid $main;
    }

    &:hover, &:hover &-avatar {
      border: 1px solid $main;
    }

    &-avatar {
      width: 49px;
      height: 49px;
      border-radius: 100px;
      border: 1px solid $ghost;
      display: flex;
      flex-shrink: 0;

      &-active {
        border: 1px solid $main;
      }

      & img {
        max-width: 100%;
        border-radius: 100px;
      }
    }

    & p {
      width: 150px;
      text-align: center;
      padding: 12px 0;
    }

    &-arrow {
      padding: 10px 15px;
      font-weight: bold;
    }
  }

  &-content {
    width: 220px;
    min-height: 50px;
    background: #FFF;
    border: 1px solid $ghost;
    border-radius: 10px;
    display: block;
    position: absolute;
    margin-top: 60px;

    & ul {
      list-style: none;
      margin: 10px 0 10px 0;
      padding: 0;

      & li {
        display: block;
        transition: 0.3s ease;

        &:hover {
          background: #F2767E;
        }

        & a {
          color: $typography;
          display: block;
          padding: 15px;
          text-decoration: none;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
