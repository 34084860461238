@import 'src/Assets/variables';

.admin-sidebar {
  width: $admin-sidebar-width;
  height: 100vh;
  background-color: rgba(242, 242, 242, .2);
  position: fixed;

  &-logo {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
  }

  &-options {
    border-bottom: 1px solid #E0E0E0;
    width: 80%;
    margin-left: 10%;
    padding-bottom: 20px;
  }

  &-option {
    margin-top: 40px;
    font-family: 'Poppins-Regular';
    font-weight: 500;
    cursor: pointer;

    &.active {
      color: $main-txt;
      font-weight: bold;

      & img {
        filter: sepia(100%) hue-rotate(190deg) saturate(500%);
      }
    }

    &-img {
      margin-right: 20px;
    }
  }

  &-logout {
    margin-left: 10%;
  }
}