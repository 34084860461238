@import 'src/Assets/variables';

.materi-gratis {
  &-header {
    margin-top: 200px;
    position: relative;

    &-img {
      position: absolute;
      right: -60px;
      top: -40px;
    }

    &-heading {
      font-family: 'Poppins-Bold';
      color: $button-main;
      font-size: 52px;
      display: block;
      width: 50%;
    }

    &-para {
      color: rgba(0, 0, 0, 0.6);
      margin-top: 20px;
      width: 50%;
    }
  }

  &-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 140px;
    margin-bottom: 40px;

    &-btn {
      width: 48%;
    }
  }

  &-toggle {
    width: 100%;
    min-height: 100px;
    padding: 30px 0;
    z-index: 2;
    background: #FFF;

    &-filter {
      padding-top: 10px;
      display: flex;

      & .active-btn-filter {
        border: 1px solid $main !important;
        color: $main !important;
        font-weight: bold;
      }
    }

    &-filter button {
      width: 175px;

      &:hover {
        color: $main !important;
        font-weight: bold;
      }
    }

    &-dropdown {
      display: inline-block;
      border-right: 1px solid #DDD;
      margin-right: 15px;
      width: 200px;
      position: relative;
      top: 12px;
      padding-right: 15px;

      .multi-select {
        --rmsc-main: $main;
        --rmsc-hover: #f1f3f5;
        --rmsc-selected: #e2e6ea;
        --rmsc-border: $main;
        --rmsc-gray: #aaa;
        --rmsc-bg: #fff;
        --rmsc-p: 10px; /* Spacing */
        --rmsc-radius: 4px; /* Radius */
        --rmsc-h: 38px; /* Height */

        & .checkbox-container {
          margin-bottom: 0px;
        }
      }

      & .multi-select .dropdown-heading {
        border-radius: 100px !important;
        border: 1px solid #EDEDED !important;
        height: 40px!important;

        &:hover {
          border: 1px solid $main!important;
          transition: 0.3s ease;
        }

        & .dropdown-heading-value {
          padding: 3px 15px 0 15px!important;
          color: #636363!important;

          & span {
            color: #636363!important;
          }
        }
      }
    }
    
    &-dropdown-order {
      height: 40px;
      margin-top: 12px;
      border: 1px solid #EDEDED !important;
      padding: 3px 15px 0 20px !important;
      border-radius: 25px;
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: #636363;
      cursor: pointer;

        span {
          width: 85%;
        }

        &:hover {
          border: 1px solid $main!important;
          transition: 0.3s ease;
        }

    }

    &-dropdown-order.active {
      border: 1px solid $main!important;
      transition: 0.3s ease;
    }

    &-dropdown-order-expand {
      width: 100%;
      position: absolute;
      top: calc(80% + 10px);
      z-index: 10;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      left: 0;
      overflow: hidden;
      cursor: pointer;

      &-list {
        padding: 15px 20px;
        transition: 0.3s ease;

        &:hover {
          color: white;
          background: $main;
        }
      }
    }

    & .btn {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED;
      margin-right: 15px;
      box-shadow: none;
    }

    & .btn-primary {
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        color: #636363!important;
        border: 1px solid $main!important;
      }
    }

    & .btn-secondary {
      padding: 8px 25px;
      border-radius: 100px !important;
      background: transparent !important;
      color: #636363;
      border: 1px solid #EDEDED!important;
      margin-right: 15px;
      box-shadow: none;

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > &.dropdown-toggle {
        color: #FFF!important;
        background: $main!important;
      }
    }

    & .btn-primary:hover {
      border: 1px solid $main !important;
    }

    & .btn-secondary:hover {
      color: #FFF !important;
      background: $main !important;
    }
  }

  &-free-materials {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 80px;
  }

  &-free-material {
    width: 31.33%;
    border: 1px solid #EBEBEB;
    border-radius: 20px;
    margin-right: 3%;
    margin-top: 30px;
    padding-bottom: 20px;

    &-container {
      cursor: pointer;
    }

    &:nth-child(3n+0){
      margin-right: 0;
    }

    &-header {
      height: 200px;
      background-color: #C4C4C4;
      border-radius: 20px 20px 0 0;
      position: relative;

      &-image {
        border-radius: 20px 20px 0 0;
      }

      &-banner {
        position: absolute;
        width: 50px;
        top: 10px; 
        right: 10px;
      }
    }

    &-labels {
      display: flex;
      margin-top: 10px;
      padding-left: 5%;

      &-label {
        margin-right: 10px;
      }
    }

    &-title {
      font-family: 'Poppins-Regular';
      font-weight: bold;
      color: $main-txt;
      display: block;
      padding-left: 5%;
      margin-top: 10px;
      padding-right: 5%;
      margin-bottom: 40px;
    }

    &-play-btn {
      display: flex;
      width: 20px;
      height: 14px;
      border-radius: 3px;
      background-color: $button-main;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      padding-left: 1px;

      &-play {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 0 4.5px 9px;
        border-color: transparent transparent transparent $white;
        border-radius: 1px;
      }
    }

    &-duration {
      font-weight: bold;
      font-size: 12px;
      margin-left: 5%;
      display: inline-flex;
    }

    &-total-questions {
      font-weight: bold;
      font-size: 12px;
      float: right;
      margin-right: 7%;
      margin-top: 4px;

      &-img {
        margin-right: 5px;
        margin-top: -7px;
      }
    }
  }

  &-btn-signup {
    width: 30%;
    margin-left: 35%;
    margin-bottom: 60px;
    background-color: $button-main;
    border-radius: 100px;
  }
}

.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  margin-top: 150px;
}

.margin-sticky {
  margin-top: 250px;
}

@media (max-width: 1280px) {
  .container-fluid {
      flex-wrap: nowrap;
      width: 100%;
  }
}