@import 'src/Assets/variables';

.preview-lesson-modal {
  &-reprocess {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &-switch {
    position: relative;
    float: right;
    margin-top: 10px;

    .onoffswitch {
      position: relative;
      width: 65px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
    
    .onoffswitch-checkbox {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
    
    .onoffswitch-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid #999999;
      border-radius: 20px;
    }
    
    .onoffswitch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
    
      &:before, &:after {
        display: block;
        float: left;
        width: 50%;
        height: 30px;
        padding: 0;
        line-height: 30px;
        font-size: 14px;
        color: white;
        font-family: Trebuchet, Arial, sans-serif;
        font-weight: bold;
        box-sizing: border-box;
      }
    
      &:before {
        content: "ID";
        padding-left: 0px;
        background-color: $secondary;
        color: #FFFFFF;
      }
    
      &:after {
        content: "EN";
        padding-right: 10px;
        background-color: #EEEEEE;
        color: #999999;
        text-align: right;
      }
    }
    
    .onoffswitch-switch {
      display: block;
      width: 18px;
      margin: 6px;
      background: #FFFFFF;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 31px;
      border: 2px solid #999999;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
    
    .onoffswitch-checkbox:checked + .onoffswitch-label {
      .onoffswitch-inner {
        margin-left: 0;
      }
    
      .onoffswitch-switch {
        right: 0px;
      }
    }
  }

  &-export-ttp {
    .onoffswitch {
      position: relative;
      width: 65px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
    
    .onoffswitch-checkbox {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
    
    .onoffswitch-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 2px solid #999999;
      border-radius: 20px;
    }
    
    .onoffswitch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
    
      &:before, &:after {
        display: block;
        float: left;
        width: 50%;
        height: 30px;
        padding: 0;
        line-height: 30px;
        font-size: 14px;
        color: white;
        font-family: Trebuchet, Arial, sans-serif;
        font-weight: bold;
        box-sizing: border-box;
      }
    
      &:before {
        content: "YES";
        padding-left: 5px;
        background-color: $secondary;
        color: #FFFFFF;
      }
    
      &:after {
        content: "NO";
        padding-right: 10px;
        background-color: #EEEEEE;
        color: #999999;
        text-align: right;
      }
    }
    
    .onoffswitch-switch {
      display: block;
      width: 18px;
      margin: 6px;
      background: #FFFFFF;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 31px;
      border: 2px solid #999999;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
    
    .onoffswitch-checkbox:checked + .onoffswitch-label {
      .onoffswitch-inner {
        margin-left: 0;
      }
    
      .onoffswitch-switch {
        right: 0px;
      }
    }
  }
}

.publish-modal-btn-main {
  .custom-button-full-width {
    height: 60px;
  }
}