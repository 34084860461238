@import 'src/Assets/variables';

.dropdown-form-component {
  &__control {
    font-size: 14px;
    height: 50px;
    padding: 0 15px;
    border-radius: 10px !important;

    &--is-focused {
      outline: 0;
      box-shadow: none;
      border: 2px solid $main !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__option {
    &--is-selected {
      background: none !important;
      color: $main-txt !important;
      font-weight: bold !important;
    }

    &--is-focused{
      background: #F2767E !important;
      color: $white !important;
    }
  }

  &__single-value {
    color: $typography !important;
  }

  &__value-container {
    padding-left: 0 !important;
  }

  &__placeholder {
    color: #BDBDBD !important;
  }

  &__clear-indicator {
    display: none !important;
  }

}
