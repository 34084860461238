@import 'src/Assets/variables';

.review-team-modal {
  &-submit {
    width: 100%;

    & svg {
      font-size: 20px;
      position: relative;
      top: -2px;
    }
  }

  &-back {
    background: none;
    outline: none;
    border: none;

    & svg {
      color: #828282;
    }

    &:focus {
      outline: none;
    }
  }

  &-list {
    width: 100%;
    padding: 0 10px;

    & span {
      display: inline-block;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 15px;
    }

    & .label-badge {
      margin-right: 10px;
    }
  }

  &-scrollable {
    width: 100%;
    max-height: 250px;
    overflow: auto;
  }

  &-content {
    & span {
      display: inline-block;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &-member {
    width: 100%;
    min-height: 50px;
    padding-top: 20px;
    margin-bottom: 0;
    cursor: pointer;

    &-profile {
      display: flex;
      flex-direction: row;
      padding-bottom: 30px;
      margin-bottom: 15px;
      border-bottom: 1px solid rgba(196, 196, 196, 0.6);

      &-avatar {
        width: 80px;
        height: 80px;

        & img {
          width: 100%;
          border-radius: 50%;
        }
      }

      &-info {
        padding: 0 15px;
        margin-bottom: 25px;

        & h2 {
          font-size: 14px;
          text-transform: uppercase;
          font-family: 'Poppins-SemiBold', serif;
          margin-bottom: 5px;
        }

        & p {
          font-size: 14px;
          color: $typography;
          font-weight: normal;
        }

        &-other {
          margin-top: -10px;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Poppins-SemiBold', serif;
          color: $secondary;

          & svg {
            color: $secondary;
            position: relative;
            top: -3px;
          }
        }

        &-company {
          margin-bottom: 0px;
        }

        &-admin {
          border-radius: 5px;
          color: #636363;
          border: none;
          font-size: 12px;
          padding: 6px 10px;
          outline: none;
          background: rgba(229, 229, 229, 0.6);

          &:focus {
            outline: none;
          }
        }

        &-rating {
          margin-top: 20px;

          &-non-active {
            font-size: 40px;
            color: #c4c4c4;
            border-radius: 2px;
          }

          &-active {
            font-size: 40px;
            color: #e3b26a;
            border-radius: 2px;
          }
        }
      }

      &-review {
        padding: 10px 0px 0px 0px;

        &-title {
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #e3b26a;
        }

        &-skill {
          padding: 5px 0px;

          & button {
            margin: 3px;
            font-weight: normal !important;
            font-size: 10px !important;
            line-height: 25px;
            padding: 10px 30px;

            &:hover {
              background: $main !important;
              color: #ffffff !important;
              outline: none !important;
            }
          }
        }

        &-optional {
          padding: 20px 0px 0px 0px;

          &-title {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #e3b26a;
          }

          &-textarea {
            width: 100%;
            height: 120px;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 13px;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            color: #636363;

            &:focus {
              outline: none;
            }

            &::placeholder {
              opacity: 0.3;
            }
          }

          & button {
            width: 100%;
            margin: 7px 0px;
          }
        }
      }

      &-add-review {
        margin-right: 0px;
        margin-left: auto;
        padding-top: 10px;

        & svg {
          position: relative;
          top: -2px;
        }
      }
    }
  }

  &-review {
    text-align: center;

    &-avatar {
      width: 80px;
      height: 80px;
      margin: 0px auto 17px auto;

      & img {
        display: block;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  &-done {

    &-image {
      padding-bottom: 13px;
    }

    &-caption {
      text-align: center;
      padding: 13px;
      margin-bottom: 48px; 

      & h4 {
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        color: #636363;
      }

      & p {
        font-size: 16px;
        line-height: 25px;
        color: #636363;
      }
    }

    &-button {
      margin-top: 28px;
      padding: 20px 0px;
    }
  }

  & .modal-header {
    border: 0;

    & svg {
      font-size: 48px;
    }
  }

  & .modal-title {
    width: 100%;
    font-size: 16px;
    font-family: 'Poppins-SemiBold', serif;
    text-align: center;
  }

  & .modal-content {
    padding: 15px 0;
    border-radius: 20px;
  }

  & .modal-body {
    padding-bottom: 0px;
    
    & .tab-content {
      padding: 30px 0 0 0;
    }

    & .nav-tabs {
      border: 0;

      & .nav-link {
        color: $line;
        border: 0;
        font-family: 'Poppins-SemiBold', serif;
        width: 50%;
        text-align: center;

        &.active {
          color: $main-txt !important;
          border-bottom: 3px solid $main-txt !important;
        }
      }
    }
  }
}
