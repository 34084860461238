@import 'src/Assets/variables';

.team-card {
  width: 100%;
  min-height: 156px;
  padding: 15px;
  border-radius: 15px;
  background: $white;
  // border: 1px solid $ghost;
  cursor: pointer;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  &-active {
    border: 1px solid $main;
  }

  &-img-bg {
    position: absolute;
    filter: grayscale(100%);
    width: 130px;
    right: 7%;
    top: 0px;
    opacity: 0.2;
  }

  &-headline {
    width: 100%;

    & h1 {
      font-size: 18px;
      color: $main;
      font-family: 'Poppins-SemiBold', serif;
      position: relative;
    }
  }

  &-label {
    width: 100%;
    margin-bottom: 15px;
  }

  &-member-preview {
    width: 100%;
    display: flex;

    &-avatar {
      width: 51px;
      height: 51px;
      border-radius: 100px;
      border: 3px solid $white;

      &:not(:first-child) {
        margin-left: -8px;
      }

      & img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 100%;
      }
    }
  }

  &-detail-component {
    width: 100%;
    position: absolute;
    bottom: 200px;
    padding: 0 15px;
    left: -50px;
    z-index: 10;
  }
}
