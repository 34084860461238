@import 'src/Assets/variables';

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  border: 1px solid $main;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #FFDCE1 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.password-change-success-modal-box {
  padding-top: 100px;
  margin-bottom: 0;

  &-close {
    text-align: right;
    font-size: 24px;
    padding: 0 30px;
    cursor: pointer;
  }

  & .modal-content {
    padding: 0;
    border-radius: 40px;
    transform: scale(0.85, 0.85);
    -ms-transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
    -moz-transform: scale(0.85, 0.85);

    & .modal-body {
      & .password-change-success-form-quick-login {
        border: 0;
        background: $main;
        color: #FFF;
      }

      & .password-change-success-form {
        width: auto;
        height: auto;
      }

      & .password-change-success-form-title {
        margin-bottom: 15px;
        text-align: center;
        font-size: 17px;
        color: #777;
        font-family: 'Poppins-SemiBold', serif;
      }

      & .password-change-success-form-desc {
        margin-bottom: 25px;
        text-align: center;
        font-size: 14px;
      }

      & .password-change-success-form-separator {
        margin-bottom: 15px;
      }

      & .form-group {
        padding: 0.5rem;
        margin-bottom: 0;
      }

      & .password-change-success-form-input-link {
        padding: 10px 0;
      }

      & .password-change-success-form-illustration {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -180px;
        left: 0;
      }

      & .password-change-success-form-quick-login-avatar {
        background: $white;
        color: $main;
      }
    }

    & .modal-footer {
      text-align: center;
      justify-content: center;

      & .password-change-success-sign-in {
        color: #E3B26A !important;
        font-weight: bold !important;
        text-decoration: none;
      }
    }
  }

}
