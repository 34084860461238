.filter-bar {
  width: 100%;
  min-height: 100px;
  background: #FFF;
  padding: 20px 0 0 0;

  & h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
}
