@import 'src/Assets/variables';

.label-badge {
  & .badge {
    padding: 10px 13px;
    font-weight: bold;
    font-family: 'Nunito-Regular';
    text-transform: uppercase;
    font-size: 11px;
  }

  & .badge-primary {
    background-color: $another-lighter;
    color: $another;
  }

  & .badge-secondary {
    background-color: rgba(223, 240, 201, 0.7);
    color: #219653;
  }

  & .badge-warning {
    background-color: rgba(254, 248, 197, 0.6);
    color: #F2994A;
    font-weight: bold;
  }

  & .badge-danger {
    background-color: #F1D7D2;
    background-color: rgba(241, 215, 210, 0.4);
    color: #EB5757;
  }

  & .badge-light {
    background-color: rgba(229, 229, 229, 0.6);
    color: $typography;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
  }
}
